import { DateTime } from 'luxon';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '@/config';
import { CAMPAIGN_SESSION_STORAGE_KEY } from '@/constants';
import { useIsExtension } from '@/features/magic/hooks/useIsExtension';
import { wrapWebViewEscape } from '@/lib/webViewUtils';
import { useExtensionPopup } from '@/pages/QuickCreate/components/GoogleCalendar/hooks/useExtensionPopup';
import {
  Button,
  type ButtonProps,
  type ComponentWithAs,
  Flex,
  Icon,
  Text,
} from '@/ui';
import { GoogleIcon } from './GoogleIcon';
import { CALENDAR_SCOPES } from './utils/googleCalendar';

type ButtonWithAsProps = ComponentProps<ComponentWithAs<'button', ButtonProps>>;

const GOOGLE_URL = 'https://accounts.google.com/o/oauth2/v2/auth/identifier';
const AUTH_REDIRECT_PATH = '/oauth/google';
const CALENDAR_REDIRECT_PATH = '/integration/google';

const SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
];

type GetSigninUrlOptions = {
  redirectTo?: string;
  authorizeCalendar?: boolean;
};

const getSigninUrl = ({
  redirectTo,
  authorizeCalendar = false,
}: GetSigninUrlOptions): string => {
  const state: Record<string, string | number | boolean> = {
    timeZone: DateTime.local().zoneName,
  };
  const campaign = sessionStorage.getItem(CAMPAIGN_SESSION_STORAGE_KEY);
  if (campaign) {
    state.campaign = campaign;
  }
  if (redirectTo) {
    state.redirectTo = redirectTo;
  }
  if (window.location.pathname.startsWith('/magic')) {
    state.app = 'magic';
  }

  const scopes = authorizeCalendar ? [...SCOPES, ...CALENDAR_SCOPES] : SCOPES;
  const redirectPath = authorizeCalendar
    ? CALENDAR_REDIRECT_PATH
    : AUTH_REDIRECT_PATH;

  const payload: Record<string, string> = {
    redirect_uri: `${config.server.url}${redirectPath}`,
    response_type: 'code',
    access_type: 'offline',
    client_id: config.googleAuth.clientId,
    scope: scopes.join(' '),
    prompt: 'consent',
    state: JSON.stringify(state),
  };

  const query = new URLSearchParams(payload);

  const signinUrl = `${GOOGLE_URL}?${query.toString()}`;

  return wrapWebViewEscape(signinUrl);
};

type Props = {
  type: 'register' | 'login' | 'calendar';
  redirectTo?: string;
};

export const GoogleSignInButton = ({
  type,
  redirectTo,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('auth');
  const { isExtension } = useIsExtension();
  const { onAuth: handlePopupAuth } = useExtensionPopup();

  const buttonProps: ButtonWithAsProps = isExtension
    ? {
        type: 'button',
        onClick: handlePopupAuth,
      }
    : {
        as: 'a',
        href: getSigninUrl({
          redirectTo,
          authorizeCalendar: type === 'calendar',
        }),
      };

  return (
    <Button
      {...buttonProps}
      bgColor="#FFF"
      border="1px solid #dadce0"
      borderRadius="full"
      color="#3c4043"
      display="flex"
      fontFamily="Roboto"
      fontSize="14px"
      fontWeight="500"
      h={38}
      justifyContent="center"
      letterSpacing="0.25px"
      overflow="hidden"
      p="0"
      pos="relative"
      transition="background-color .218s"
      _hover={{
        borderColor: '#d2e3fc',
        backgroundColor: 'rgba(66, 133, 244, .04)',
      }}
    >
      <Flex align="center" left={-1} pos="relative">
        <Icon as={GoogleIcon} />
        <Text>
          {type === 'login' ? t('google_login') : t('google_register')}
        </Text>
      </Flex>
    </Button>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthPanel, useFrom, useServerCodeToast } from '@/components/auth';
import { useCampaignTracking } from '@/hooks/useCampaignTracking';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useMetaThemeColor } from '@/hooks/useMetaThemeColor';

export const Auth = () => {
  const from = useFrom();
  const [defaults, setDefaults] = useState({});
  const navigate = useNavigate();
  useServerCodeToast();
  useCampaignTracking();

  useLauncherAction('signup', setDefaults);
  useMetaThemeColor('white');

  return (
    <AuthPanel
      defaults={defaults}
      redirectTo={from?.destination}
      onAuthenticated={(result, data) => {
        const welcomeScheduleId =
          data && 'register' in data && data.register.schedules[0]?.id;

        let redirectTo = '/dashboard';
        if (from) {
          if (from.isServerRoute) {
            window.location.href = from.destination;
            return;
          }
          redirectTo = from.destination;
        } else if (welcomeScheduleId) {
          redirectTo = `/schedule/${welcomeScheduleId}`;
        }

        navigate(redirectTo, { replace: true });
      }}
    />
  );
};

import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { TIME_ZONE_UTC } from '@/constants';
import { MagicEventFragment } from '@/features/events/fragments/MagicEventFragment';
import type { DecoratedMagicEvent } from '@/features/magic/types';
import { gqlClient } from '@/lib';
import { datePartInZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import type {
  UpdateMagicEventMutation,
  UpdateMagicEventMutationVariables,
} from './useUpdateMagicEvent.generated';

const query = gql`
  ${MagicEventFragment}
  mutation UpdateMagicEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      ...MagicEvent
    }
  }
`;

export const useUpdateMagicEvent = () => {
  const { mutate, ...rest } = useMutation<
    UpdateMagicEventMutation,
    QueryError,
    UpdateMagicEventMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateMagicEventMutation,
        UpdateMagicEventMutationVariables
      >(query, variables),
  });

  const updateMagicEvent = useCallback(
    (
      entry: DecoratedMagicEvent,
      magicJobId: string,
      options?: MutateOptions<
        UpdateMagicEventMutation,
        QueryError,
        UpdateMagicEventMutationVariables
      >
    ) => {
      const {
        id,
        startDate,
        endDate,
        isAllDay,
        timeZone,
        ...entryWithoutRecurrence
      } = entry;

      const start: UpdateMagicEventMutationVariables['input']['start'] = {};
      const end: UpdateMagicEventMutationVariables['input']['end'] = {};

      if (isAllDay) {
        start.dateOnly = {
          date: datePartInZone(startDate, TIME_ZONE_UTC).toISODate(),
        };
        end.dateOnly = {
          date: datePartInZone(endDate, TIME_ZONE_UTC).toISODate(),
        };
      } else {
        start.dateTime = {
          dateTime: startDate.toUTC().toISO(),
          timeZone,
        };
        end.dateTime = {
          dateTime: endDate.toUTC().toISO(),
          timeZone,
        };
      }

      const input: UpdateMagicEventMutationVariables['input'] = {
        ...entryWithoutRecurrence,
        for: { magicJobId },
        eventId: id,
        start,
        end,
      };
      return mutate({ input }, options);
    },
    [mutate]
  );

  return { updateMagicEvent, ...rest };
};

import { lazy } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { AuthLayout } from '@/components/auth';
import { Banner } from '@/components/Banner';
import { config } from '@/config';
import { Auth, ResetPasswordRequest, ResetPassword } from '@/pages';
import { Launch } from '@/pages/Launch';
import { configs as pocConfigs } from '@/pages/QuickCreate/pages/PageExperiencePoC/config';
import { GlobalComponents } from './components/GlobalComponents';
import { MagicTabsIndex } from './components/MagicTabsIndex';
import { MagicTabsLayout } from './components/MagicTabsLayout';
import { Redirect } from './components/Redirect';
import { RequireAuth } from './components/RequireAuth';
import { ScheduleLoader } from './components/ScheduleLoader';
import { SnippetSuspenseFallback } from './components/SnippetSuspenseFallback';
import { SuspenseContainer } from './components/SuspenseContainer';

const Dashboard = lazy(() => import('@/pages/Dashboard'));
const Schedule = lazy(() => import('@/pages/Schedule'));
const ScheduleMessages = lazy(
  () => import('@/pages/Schedule/pages/ScheduleMessages')
);
const ScheduleSnippets = lazy(
  () => import('@/pages/Schedule/pages/ScheduleSnippets')
);
const ScheduleSnippet = lazy(
  () => import('@/pages/Schedule/pages/ScheduleSnippet')
);
const ScheduleSources = lazy(
  () => import('@/pages/Schedule/pages/ScheduleSources')
);
const SchedulePrompt = lazy(
  () => import('@/pages/Schedule/pages/SchedulePrompt')
);
const ScheduleEntry = lazy(
  () => import('@/pages/Schedule/pages/ScheduleEntry')
);
const QuickCreate = lazy(() => import('@/pages/QuickCreate'));
const QuickCreateExtension = lazy(() => import('@/features/magic/extension'));
const QuickCreateAuth = lazy(
  () => import('@/features/magic/extension/pages/QuickCreateAuth')
);
const QuickCreateAuthSuccess = lazy(
  () => import('@/features/magic/extension/pages/QuickCreateAuthSuccess')
);
const MagicToPage = lazy(() => import('@/features/magic/pages/MagicPage'));

const PageExperiencePoC = lazy(
  () => import('@/pages/QuickCreate/pages/PageExperiencePoC')
);

const EditPageExperience = lazy(
  () => import('@/features/experiences/pages/EditPageExperience')
);
const ViewPageExperience = lazy(
  () => import('@/features/experiences/pages/ViewPageExperience')
);

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <GlobalComponents />

      <Routes>
        {/* Unauthenticated routes */}
        <Route element={<AuthLayout mt={{ base: 4, sm: 12 }} />} path="/">
          <Route element={<Auth />} path="auth" />
          <Route element={<Redirect path="/auth" />} path="register" />
          <Route element={<Redirect path="/auth" />} path="login" />
          <Route path="reset-password">
            <Route element={<ResetPasswordRequest />} index />
            <Route element={<ResetPassword />} path=":token" />
          </Route>
        </Route>

        {/* Magic & dashboard tabs */}
        {Object.entries(pocConfigs).map(([slug, pocConfig]) => {
          // only some PoC's can be routed to in production
          const shouldRenderRoute =
            pocConfig.isLive ||
            config.isPreviewDeploy ||
            config.env === 'development';

          return (
            <Route
              key={slug}
              path={`magic/p/${slug}`}
              element={
                shouldRenderRoute ? <PageExperiencePoC slug={slug} /> : null
              }
            />
          );
        })}

        <Route element={<MagicTabsLayout />} path="/">
          <Route element={<MagicTabsIndex />} index />
          <Route element={<QuickCreate />} path="magic/*" />
          <Route element={<Dashboard />} path="dashboard" />
        </Route>
        <Route element={<MagicToPage />} path="magic-page" />

        <Route
          path="/launch/auth"
          element={
            <RequireAuth>
              <Launch />
            </RequireAuth>
          }
        />

        <Route
          element={
            <>
              <Banner />
              <Outlet />
            </>
          }
        >
          <Route
            path="/prompt"
            element={
              <RequireAuth>
                <SuspenseContainer>
                  <SchedulePrompt />
                </SuspenseContainer>
              </RequireAuth>
            }
          />
          <Route element={<ScheduleLoader />} path="/schedule">
            <Route element={<Navigate to="/" />} index />
            <Route path=":slug">
              <Route element={<Schedule />} index path="*" />
              <Route element={<ScheduleSources />} path="sources/*" />
              <Route element={<ScheduleSnippets />} path="snippets" />
              <Route path="messages">
                <Route element={<Navigate to="scheduled" />} index />
                <Route element={<ScheduleMessages />} path=":view" />
              </Route>
              <Route path="item">
                <Route element={<Navigate to="../" />} index />
                <Route element={<ScheduleEntry />} path=":entryId" />
                <Route
                  element={<ScheduleEntry />}
                  path=":entryId/:instanceTimestamp"
                />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* Maybe authenticated routes */}
        <Route
          path="/snippet"
          element={
            <SuspenseContainer fallback={<SnippetSuspenseFallback />}>
              <Outlet />
            </SuspenseContainer>
          }
        >
          <Route element={<Navigate replace to="/" />} index />
          <Route element={<ScheduleSnippet />} path=":snippetId" />
        </Route>

        {/* Magic extension */}
        <Route
          path="/magic-extension"
          element={
            <SuspenseContainer
              fallback={<AppLoader layerStyle="magicBackground" />}
            >
              <QuickCreateExtension />
            </SuspenseContainer>
          }
        />
        <Route
          path="/magic-auth"
          element={
            <SuspenseContainer>
              <Outlet />
            </SuspenseContainer>
          }
        >
          <Route element={<QuickCreateAuth />} index />
          <Route element={<QuickCreateAuthSuccess />} path="success" />
        </Route>

        <Route
          path="/experience/:id/edit"
          element={
            <SuspenseContainer>
              <EditPageExperience />
            </SuspenseContainer>
          }
        />
        <Route
          path="/page/:slug"
          element={
            <SuspenseContainer>
              <ViewPageExperience />
            </SuspenseContainer>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

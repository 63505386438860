import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import type { AuthLocationState } from '../types';

type From = {
  destination: string;
  isServerRoute: boolean;
};

const SERVER_NEW_PATH = '/new';

const locationToHref = (location?: Location): string | undefined => {
  if (!location) {
    return undefined;
  }
  return `${location.pathname}${location.search}`;
};

export const useFrom = (): From | undefined => {
  const { queryParams } = useQueryParams();
  const { state: locationState } = useLocation() as AuthLocationState;
  const from = queryParams.get('from') || locationToHref(locationState?.from);
  const isNew = Boolean(queryParams.get('new') || from === SERVER_NEW_PATH);
  const destination = isNew ? SERVER_NEW_PATH : from;

  if (!destination) {
    return undefined;
  }

  return {
    destination,
    isServerRoute: destination === SERVER_NEW_PATH,
  };
};

import { useEffect } from 'react';
import heroDesktop from '@/features/magic/components/Header/hero-desktop.png';
import heroMobile from '@/features/magic/components/Header/hero-mobile.png';

const imagesToPreload = [heroDesktop, heroMobile];

export const usePreloadAssets = () => {
  useEffect(() => {
    imagesToPreload.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);
};

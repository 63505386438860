import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { PlatformTypes } from '@/types/gql.generated';

export enum Activity {
  AgendaHero = PlatformTypes.AgendaHero,
  GoogleCalendar = PlatformTypes.GoogleIntegration,
}

type State = {
  activity: Activity | null;
  open: (activity: Activity) => void;
  close: () => void;
  toggle: (activity: Activity) => void;
};

export const useMultiExportStore = createWithEqualityFn<State>()(
  (set) => ({
    activity: null,
    open: (activity) => set({ activity }),
    close: () => set({ activity: null }),
    toggle: (activity) =>
      set((state) => ({
        activity: activity === state.activity ? null : activity,
      })),
  }),
  shallow
);

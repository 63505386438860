export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateOnly: { input: string; output: string };
  DateTime: { input: string; output: string };
  EmailAddress: { input: string; output: string };
  LocalTime: { input: string; output: string };
  LowercaseString: { input: string; output: string };
  Mixed: { input: any; output: any };
  PhoneNumber: { input: string; output: string };
  TimeZone: { input: string; output: string };
  Upload: { input: unknown; output: unknown };
  Void: { input: void; output: void };
};

export type AddExperiencePermissionsInput = {
  experienceId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  users: Array<UserPermissionInput>;
};

export enum AddToCalendarConfigKeys {
  Mode = 'mode',
}

export enum AddToCalendarConfigMode {
  EmailInvite = 'EMAIL_INVITE',
  None = 'NONE',
  WebLink = 'WEB_LINK',
}

export enum AttendeeStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export enum AttributionOptions {
  AvatarFullName = 'AVATAR_FULL_NAME',
  None = 'NONE',
}

export enum AuthAction {
  LoginGoogle = 'LOGIN_GOOGLE',
  LoginPassword = 'LOGIN_PASSWORD',
  Register = 'REGISTER',
}

export type AvailabilityEntryDetails = {
  id: Scalars['ID']['output'];
  recurrences: Array<Recurrence>;
  timeZone: Scalars['TimeZone']['output'];
  title: Scalars['String']['output'];
};

export type AvailabilityResponse = {
  email: Scalars['LowercaseString']['output'];
  id: Scalars['ID']['output'];
  items: Array<EntryAvailability>;
  name: Scalars['String']['output'];
  submittedAt: Scalars['DateTime']['output'];
};

export type AvailabilityResponseInput = {
  email: Scalars['LowercaseString']['input'];
  items: Array<EntryAvailabilityInput>;
  name: Scalars['String']['input'];
};

export enum AvailabilityType {
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export enum BannerOptions {
  None = 'NONE',
  Ratio_7_2 = 'RATIO_7_2',
  RatioIntrinsic = 'RATIO_INTRINSIC',
}

export type Base64FileInput = {
  data: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
};

export type Block = {
  id: Scalars['ID']['output'];
  layoutId: BlockLayouts;
  sequence: Scalars['Int']['output'];
  type: BlockTypes;
};

export type BlockDefinition = {
  layouts: Array<BlockLayouts>;
  slots: Array<InteractionSlotDefinition>;
  type: BlockTypes;
};

export enum BlockLayouts {
  ListByDay = 'ListByDay',
  ListByEvent = 'ListByEvent',
  ListByMonth = 'ListByMonth',
  ListByWeek = 'ListByWeek',
  ListByYear = 'ListByYear',
  SideHeader = 'SideHeader',
  VerticalListFooter = 'VerticalListFooter',
  VerticalListHeader = 'VerticalListHeader',
}

export enum BlockTypes {
  Collection = 'COLLECTION',
  Footer = 'FOOTER',
  Header = 'HEADER',
}

export type Calendar = {
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  readonly: Scalars['Boolean']['output'];
  scheduleCount: Scalars['Int']['output'];
  uri: Scalars['String']['output'];
};

export type Category = {
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortOrder: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type CategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CategorySortOrderInput = {
  id: Scalars['ID']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type CheckSlugAvailabilityInput = {
  slug: Scalars['LowercaseString']['input'];
};

export enum ColorTypes {
  Custom = 'CUSTOM',
  Standard = 'STANDARD',
}

export type CompleteMagicJobInput = {
  magicJobId: Scalars['ID']['input'];
  timeZone: Scalars['TimeZone']['input'];
};

export type CreateEntryInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  entryId?: InputMaybe<Scalars['ID']['input']>;
  exclusions?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hidden?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  inclusions?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  labels?: InputMaybe<Array<LabelIdInput>>;
  locationWithPlace?: InputMaybe<LocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  recurrences: Array<RecurrenceInput>;
  scheduleId: Scalars['ID']['input'];
  source?: InputMaybe<CreateEntrySource>;
  timeZone: Scalars['TimeZone']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  whoLabels?: InputMaybe<Array<LabelIdInput>>;
};

export enum CreateEntrySource {
  Magic = 'MAGIC',
  Manual = 'MANUAL',
}

export type CreateEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  end: EventDateInput;
  experienceId: Scalars['String']['input'];
  locationWithPlace?: InputMaybe<LocationInput>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  start: EventDateInput;
  title: Scalars['String']['input'];
};

export type CreateEventsInput = {
  events: Array<CreateEventInput>;
  experienceId: Scalars['String']['input'];
};

export type CreateExperienceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<ExperienceLayouts>;
  magicJobId?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: ExperienceTypes;
};

export type CreateMagicJobFrom = {
  base64File?: InputMaybe<Base64FileInput>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMagicJobInput = {
  from: CreateMagicJobFrom;
  prompt?: InputMaybe<Scalars['String']['input']>;
  source: MagicJobSource;
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type DeleteEventInput = {
  eventId: Scalars['ID']['input'];
  for?: InputMaybe<EventFor>;
};

export type DeleteExperienceInput = {
  experienceId: Scalars['String']['input'];
};

export type DeliveryAttempt = {
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['PhoneNumber']['output'];
  status: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum DeliveryTimeType {
  Fixed = 'FIXED',
  Hybrid = 'HYBRID',
  Relative = 'RELATIVE',
}

export enum EditorPermissions {
  AddToCalendar = 'ADD_TO_CALENDAR',
  EntryCreate = 'ENTRY_CREATE',
  EntryDelete = 'ENTRY_DELETE',
  EntryUpdate = 'ENTRY_UPDATE',
  Messages = 'MESSAGES',
  PermissionsModify = 'PERMISSIONS_MODIFY',
  Rsvp = 'RSVP',
  ScheduleExport = 'SCHEDULE_EXPORT',
  ScheduleImport = 'SCHEDULE_IMPORT',
  ScheduleModifySettings = 'SCHEDULE_MODIFY_SETTINGS',
  ScheduleRename = 'SCHEDULE_RENAME',
  ScheduleView = 'SCHEDULE_VIEW',
  SnippetEdit = 'SNIPPET_EDIT',
  SnippetView = 'SNIPPET_VIEW',
}

export type EntriesJob = {
  createdAt: Scalars['DateTime']['output'];
  entries?: Maybe<Array<Entry>>;
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  file?: Maybe<EntriesJobFile>;
  headerId?: Maybe<ScheduleHeaderId>;
  id: Scalars['ID']['output'];
  prompt?: Maybe<Scalars['String']['output']>;
  scheduleId: Scalars['ID']['output'];
  status: JobStatus;
  title?: Maybe<Scalars['String']['output']>;
  type: EntriesJobType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntriesJobFile = {
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  mimetype: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum EntriesJobType {
  File = 'FILE',
  Prompt = 'PROMPT',
}

export type Entry = {
  attachments: Array<EntryAttachment>;
  category?: Maybe<Category>;
  contextId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  exclusions: Array<Scalars['DateTime']['output']>;
  feed?: Maybe<FeedSummary>;
  hidden: Array<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inclusions: Array<Scalars['DateTime']['output']>;
  invites: Array<EntryInvite>;
  /** @deprecated Use rsvpInfo instead */
  isFollowing: Scalars['Boolean']['output'];
  labels: Array<Label>;
  locationWithPlace?: Maybe<Location>;
  messages: Array<EntryMessage>;
  notes?: Maybe<Scalars['String']['output']>;
  recurrences: Array<Recurrence>;
  rsvpInfo: RsvpInfo;
  rsvpSettings: EntryRsvpSettings;
  status: EntryStatus;
  timeZone: Scalars['TimeZone']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: EntryType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whoLabels: Array<Label>;
};

export type EntryAttachment = {
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EntryAvailability = {
  entry: AvailabilityEntryDetails;
  id: Scalars['ID']['output'];
  instance: Scalars['DateTime']['output'];
  note?: Maybe<Scalars['String']['output']>;
  status: AvailabilityType;
};

export type EntryAvailabilityInput = {
  entryId: Scalars['ID']['input'];
  instance: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  status: AvailabilityType;
};

export type EntryInvite = {
  email: Scalars['LowercaseString']['output'];
  id: Scalars['ID']['output'];
  memberSubmission?: Maybe<Array<MemberFormFieldData>>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  status: AttendeeStatus;
};

export type EntryMessage = {
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryAttempts: Array<DeliveryAttempt>;
  deliveryTimeType: DeliveryTimeType;
  entry: Entry;
  fixedDeliveryTime?: Maybe<Scalars['DateTime']['output']>;
  fixedTimeZone?: Maybe<Scalars['TimeZone']['output']>;
  hybridDeliveryTime?: Maybe<Scalars['LocalTime']['output']>;
  hybridRelativeTime?: Maybe<HybridRelativeTime>;
  hybridTimeZone?: Maybe<Scalars['TimeZone']['output']>;
  id: Scalars['ID']['output'];
  instance: Scalars['DateTime']['output'];
  recipients: Array<Member>;
  relativeTimeToEntryStart?: Maybe<Scalars['Int']['output']>;
  relativeTimeToEntryStartDirection?: Maybe<TimeDirection>;
  relativeTimeToEntryStartUnit?: Maybe<TimeUnit>;
  sendAt: Scalars['DateTime']['output'];
  sendToEveryone: Scalars['Boolean']['output'];
  status?: Maybe<MessageStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntryMessageInput = {
  body: Scalars['String']['input'];
  deliveryTimeType: DeliveryTimeType;
  fixedDeliveryTime?: InputMaybe<Scalars['DateTime']['input']>;
  fixedTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  hybridDeliveryTime?: InputMaybe<Scalars['LocalTime']['input']>;
  hybridRelativeTime?: InputMaybe<HybridRelativeTime>;
  hybridTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instance: Scalars['DateTime']['input'];
  recipients?: InputMaybe<Array<Scalars['ID']['input']>>;
  relativeTimeToEntryStart?: InputMaybe<Scalars['Int']['input']>;
  relativeTimeToEntryStartDirection?: InputMaybe<TimeDirection>;
  relativeTimeToEntryStartUnit?: InputMaybe<TimeUnit>;
  sendToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EntryRsvpSettings = {
  enabled: Scalars['Boolean']['output'];
};

export enum EntryStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum EntryType {
  Feed = 'FEED',
  Schedule = 'SCHEDULE',
}

export enum ErrorCodes {
  BadRequest = 'BAD_REQUEST',
  BlockNotFound = 'BLOCK_NOT_FOUND',
  CategoryInUse = 'CATEGORY_IN_USE',
  CategoryNotFound = 'CATEGORY_NOT_FOUND',
  ConflictFormNotSupported = 'CONFLICT_FORM_NOT_SUPPORTED',
  EntryAlreadyAddedToCalendar = 'ENTRY_ALREADY_ADDED_TO_CALENDAR',
  EntryMessageInPast = 'ENTRY_MESSAGE_IN_PAST',
  EntryMessageNotFound = 'ENTRY_MESSAGE_NOT_FOUND',
  EntryMessageNotScheduled = 'ENTRY_MESSAGE_NOT_SCHEDULED',
  EntryNotFound = 'ENTRY_NOT_FOUND',
  EntryScheduledMessageLimitReached = 'ENTRY_SCHEDULED_MESSAGE_LIMIT_REACHED',
  EventDateInvalid = 'EVENT_DATE_INVALID',
  EventDateRuleInvalid = 'EVENT_DATE_RULE_INVALID',
  EventDateTypeMismatch = 'EVENT_DATE_TYPE_MISMATCH',
  EventInviteNotFound = 'EVENT_INVITE_NOT_FOUND',
  EventNotFound = 'EVENT_NOT_FOUND',
  EventNotPartOfExperience = 'EVENT_NOT_PART_OF_EXPERIENCE',
  ExperienceLayoutInvalid = 'EXPERIENCE_LAYOUT_INVALID',
  ExperienceNotFound = 'EXPERIENCE_NOT_FOUND',
  ExperiencePublishStateError = 'EXPERIENCE_PUBLISH_STATE_ERROR',
  ExperienceSlugExists = 'EXPERIENCE_SLUG_EXISTS',
  FeedInvalid = 'FEED_INVALID',
  FeedInUse = 'FEED_IN_USE',
  FeedNotFound = 'FEED_NOT_FOUND',
  FeedUrlFailed = 'FEED_URL_FAILED',
  FeedUrlNotFound = 'FEED_URL_NOT_FOUND',
  FileRequired = 'FILE_REQUIRED',
  IntegrationDisconnected = 'INTEGRATION_DISCONNECTED',
  IntegrationInsufficientScope = 'INTEGRATION_INSUFFICIENT_SCOPE',
  IntegrationNotFound = 'INTEGRATION_NOT_FOUND',
  InteractionAppNotFound = 'INTERACTION_APP_NOT_FOUND',
  InteractionConfigInvalid = 'INTERACTION_CONFIG_INVALID',
  InteractionSlotNotFound = 'INTERACTION_SLOT_NOT_FOUND',
  InvalidFileType = 'INVALID_FILE_TYPE',
  InvalidInviteCode = 'INVALID_INVITE_CODE',
  InvalidLabel = 'INVALID_LABEL',
  InvalidLogin = 'INVALID_LOGIN',
  InvalidPermission = 'INVALID_PERMISSION',
  JobNotFound = 'JOB_NOT_FOUND',
  LabelExists = 'LABEL_EXISTS',
  LabelInUse = 'LABEL_IN_USE',
  LayoutConfigInvalid = 'LAYOUT_CONFIG_INVALID',
  LayoutNotFound = 'LAYOUT_NOT_FOUND',
  MagicJobNotFound = 'MAGIC_JOB_NOT_FOUND',
  MemberFormInvalidEntry = 'MEMBER_FORM_INVALID_ENTRY',
  MemberFormNotSupported = 'MEMBER_FORM_NOT_SUPPORTED',
  MerValidationErrFailedValidation = 'MER_VALIDATION_ERR_FAILED_VALIDATION',
  NotLoggedIn = 'NOT_LOGGED_IN',
  NoContent = 'NO_CONTENT',
  NoResponse = 'NO_RESPONSE',
  PermissionNotGranted = 'PERMISSION_NOT_GRANTED',
  PromptRequestInvalid = 'PROMPT_REQUEST_INVALID',
  PromptRequestTooLong = 'PROMPT_REQUEST_TOO_LONG',
  PromptResponseParseError = 'PROMPT_RESPONSE_PARSE_ERROR',
  PromptResponseTooLong = 'PROMPT_RESPONSE_TOO_LONG',
  PromptTimeout = 'PROMPT_TIMEOUT',
  QuickEntriesJobNotFound = 'QUICK_ENTRIES_JOB_NOT_FOUND',
  QuickEntriesPageNotFound = 'QUICK_ENTRIES_PAGE_NOT_FOUND',
  QuickEntryNotFound = 'QUICK_ENTRY_NOT_FOUND',
  ResetTokenExpired = 'RESET_TOKEN_EXPIRED',
  ResetTokenInvalid = 'RESET_TOKEN_INVALID',
  ResetTokenUsed = 'RESET_TOKEN_USED',
  RsvpNotFound = 'RSVP_NOT_FOUND',
  ScheduleMemberLimitReached = 'SCHEDULE_MEMBER_LIMIT_REACHED',
  ScheduleNotFound = 'SCHEDULE_NOT_FOUND',
  ScheduleSlugExists = 'SCHEDULE_SLUG_EXISTS',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  SnippetNotFound = 'SNIPPET_NOT_FOUND',
  SubscriberExists = 'SUBSCRIBER_EXISTS',
  SubscriberNotFound = 'SUBSCRIBER_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
  UploadFailed = 'UPLOAD_FAILED',
  UploadLimitExceeded = 'UPLOAD_LIMIT_EXCEEDED',
  UserExists = 'USER_EXISTS',
  UserNotFound = 'USER_NOT_FOUND',
}

export type Event = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  end: EventDate;
  firstStartDate: Scalars['DateOnly']['output'];
  firstStartDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAllDay: Scalars['Boolean']['output'];
  lastEndDate: Scalars['DateOnly']['output'];
  lastEndDateTime: Scalars['DateTime']['output'];
  locationWithPlace?: Maybe<Location>;
  rrule?: Maybe<Scalars['String']['output']>;
  start: EventDate;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Int']['output'];
};

export type EventCardDefinition = {
  layout: EventCardLayouts;
  supportsDate: Scalars['Boolean']['output'];
  supportsDescription: Scalars['Boolean']['output'];
  supportsEmoji: Scalars['Boolean']['output'];
  supportsLocation: Scalars['Boolean']['output'];
};

export enum EventCardLayouts {
  ListEventCard = 'ListEventCard',
}

export type EventDate = {
  date?: Maybe<Scalars['DateOnly']['output']>;
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
};

export type EventDateInput = {
  dateOnly?: InputMaybe<EventDateOnlyInput>;
  dateTime?: InputMaybe<EventDateTimeInput>;
};

export type EventDateOnlyInput = {
  date: Scalars['DateOnly']['input'];
};

export type EventDateTimeInput = {
  dateTime: Scalars['DateTime']['input'];
  timeZone: Scalars['TimeZone']['input'];
};

export type EventExport = {
  calendarId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['ID']['output'];
  externalId: Scalars['String']['output'];
  externalLink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type EventFor = {
  experienceId?: InputMaybe<Scalars['ID']['input']>;
  magicJobId?: InputMaybe<Scalars['ID']['input']>;
};

export enum EventListDisplay {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
}

export type Experience = {
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  attributionDisplay: AttributionOptions;
  bannerDisplay: BannerOptions;
  bannerImageId?: Maybe<Scalars['String']['output']>;
  bannerImageType?: Maybe<ImageTypes>;
  bannerImageUrl?: Maybe<Scalars['String']['output']>;
  blocks: Array<Block>;
  bold: Scalars['Int']['output'];
  collectionId: Scalars['String']['output'];
  colorType: ColorTypes;
  createdAt: Scalars['DateTime']['output'];
  customAlpha: Scalars['Float']['output'];
  customHue?: Maybe<Scalars['Int']['output']>;
  customLightness?: Maybe<Scalars['Int']['output']>;
  customSaturation?: Maybe<Scalars['Int']['output']>;
  defaultInteractionAppId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEnabled: Scalars['Boolean']['output'];
  eventCardDateEnabled: Scalars['Boolean']['output'];
  eventCardDescriptionEnabled: Scalars['Boolean']['output'];
  eventCardEmojiEnabled: Scalars['Boolean']['output'];
  eventCardLayoutId: EventCardLayouts;
  eventCardLocationEnabled: Scalars['Boolean']['output'];
  eventListDisplay: EventListDisplay;
  events: Array<Event>;
  hue: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  interactionApps: Array<InteractionApp>;
  interactionSlots: Array<InteractionSlot>;
  isSaved: Scalars['Boolean']['output'];
  layout: LayoutDefinition;
  layoutId: ExperienceLayouts;
  lightness: Scalars['Int']['output'];
  logoDisplay: LogoOptions;
  logoImageId?: Maybe<Scalars['String']['output']>;
  logoImageType?: Maybe<ImageTypes>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  openedAt?: Maybe<Scalars['DateTime']['output']>;
  owner: PublicUser;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedSlug?: Maybe<Scalars['String']['output']>;
  role: Role;
  saturation: Scalars['Int']['output'];
  sparkle: Scalars['Int']['output'];
  subscribeEnabled: Scalars['Boolean']['output'];
  theme?: Maybe<ExperienceTheme>;
  timeZone: Scalars['TimeZone']['output'];
  timeZoneDisplay: TimeZoneDisplay;
  title: Scalars['String']['output'];
  type: ExperienceTypes;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExperienceEventsArgs = {
  filters?: InputMaybe<ListExperienceEventInput>;
};

export type ExperienceInteractionSlotsArgs = {
  filters?: InputMaybe<ListExperienceInteractionSlotInput>;
};

export enum ExperienceImageTypes {
  Banner = 'BANNER',
  Logo = 'LOGO',
}

export enum ExperienceLayouts {
  ListVertical = 'listVertical',
  SideHeader = 'sideHeader',
}

export type ExperienceTheme = {
  bannerImageId?: Maybe<Scalars['String']['output']>;
  bannerImageType?: Maybe<ImageTypes>;
  bold?: Maybe<Scalars['Int']['output']>;
  colorType?: Maybe<ColorTypes>;
  createdAt: Scalars['DateTime']['output'];
  customHue?: Maybe<Scalars['Int']['output']>;
  customLightness?: Maybe<Scalars['Int']['output']>;
  customSaturation?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lightness?: Maybe<Scalars['Int']['output']>;
  logoImageId?: Maybe<Scalars['String']['output']>;
  logoImageType?: Maybe<ImageTypes>;
  name: Scalars['String']['output'];
  saturation?: Maybe<Scalars['Int']['output']>;
  sparkle?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ExperienceTypes {
  Page = 'PAGE',
}

export type ExportEventsInput = {
  attendees?: InputMaybe<Array<Scalars['EmailAddress']['input']>>;
  calendarId: Scalars['String']['input'];
  eventIds: Array<Scalars['ID']['input']>;
  integrationId: Scalars['ID']['input'];
};

export enum Feature {
  CustomColors = 'CUSTOM_COLORS',
  CustomTheme = 'CUSTOM_THEME',
  CustomUrl = 'CUSTOM_URL',
}

export type Feed = {
  category?: Maybe<Category>;
  emoji?: Maybe<Scalars['String']['output']>;
  entries: Array<Entry>;
  error?: Maybe<Scalars['String']['output']>;
  erroredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  integration?: Maybe<FeedIntegration>;
  labels: Array<Label>;
  primarySchedule?: Maybe<FeedPrimarySchedule>;
  /** @deprecated No longer used */
  schedules: Array<Scalars['ID']['output']>;
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: FeedType;
  url: Scalars['String']['output'];
  whoLabels: Array<Label>;
};

export type FeedEntriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedIntegration = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: User;
  type: IntegrationType;
};

export type FeedPrimarySchedule = {
  id: Scalars['ID']['output'];
};

export type FeedSecret = {
  enabled: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
};

export type FeedSummary = {
  id: Scalars['ID']['output'];
  primarySchedule?: Maybe<FeedPrimarySchedule>;
  title?: Maybe<Scalars['String']['output']>;
  type: FeedType;
  url: Scalars['String']['output'];
};

export enum FeedType {
  External = 'EXTERNAL',
  Google = 'GOOGLE',
  Internal = 'INTERNAL',
}

export type GetExperienceInput = {
  experienceId: Scalars['String']['input'];
};

export type GetExperiencePermissionsInput = {
  experienceId: Scalars['String']['input'];
};

export type GetExperienceThemesInput = {
  experienceId: Scalars['String']['input'];
};

export type GetLayoutInput = {
  layoutId: ExperienceLayouts;
};

export type GetMagicJobInput = {
  magicJobId: Scalars['ID']['input'];
};

export type GetPublishedExperienceInput = {
  experienceSlug: Scalars['String']['input'];
};

export enum HybridRelativeTime {
  DayAfter = 'DAY_AFTER',
  DayBefore = 'DAY_BEFORE',
  DayOf = 'DAY_OF',
}

export enum IdentityStrategy {
  Google = 'GOOGLE',
  Password = 'PASSWORD',
}

export enum ImageTypes {
  Custom = 'CUSTOM',
}

export type ImportMagicJobInput = {
  experienceId: Scalars['String']['input'];
  magicJobId: Scalars['String']['input'];
};

export type InstallInteractionAppInput = {
  applicationName: InteractionAppNames;
  experienceId: Scalars['String']['input'];
  isDefaultApp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Integration = {
  calendars: Array<Calendar>;
  createdAt: Scalars['DateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  status: IntegrationStatus;
  type: IntegrationType;
};

export enum IntegrationStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum IntegrationType {
  GoogleCalendar = 'GOOGLE_CALENDAR',
}

export type InteractionApp = {
  applicationName: InteractionAppNames;
  config: Array<InteractionAppSetting>;
  id: Scalars['ID']['output'];
};

export type InteractionAppConfigInput = {
  config: Array<InteractionAppSettingInput>;
  experienceId: Scalars['String']['input'];
  interactionAppId: Scalars['String']['input'];
};

export enum InteractionAppNames {
  AddToCalendar = 'ADD_TO_CALENDAR',
  /** Extended by app schema - don't use _empty */
  Empty = '_empty',
}

export type InteractionAppSetting = {
  setting: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type InteractionAppSettingInput = {
  setting: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InteractionSlot = {
  blockId: Scalars['String']['output'];
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interactionAppId?: Maybe<Scalars['String']['output']>;
  slotIndex: Scalars['Int']['output'];
};

export type InteractionSlotDefinition = {
  size: SlotSize;
};

export enum JobStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Delayed = 'DELAYED',
  Failed = 'FAILED',
  Reviewed = 'REVIEWED',
  Waiting = 'WAITING',
}

export type Label = {
  id: Scalars['ID']['output'];
  sortOrder: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type LabelIdInput = {
  id: Scalars['ID']['input'];
};

export type LabelInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type LabelSortOrderInput = {
  id: Scalars['ID']['input'];
  sortOrder: Scalars['Int']['input'];
};

export enum LabelType {
  Default = 'DEFAULT',
  Who = 'WHO',
}

export type LayoutDefinition = {
  attributionOptions: Array<AttributionOptions>;
  bannerOptions: Array<BannerOptions>;
  blocks: Array<BlockDefinition>;
  cardOptions: Array<EventCardDefinition>;
  id: ExperienceLayouts;
  logoOptions: Array<LogoOptions>;
  supportsDescription: Scalars['Boolean']['output'];
  type: ExperienceTypes;
};

export type LeaveExperienceInput = {
  experienceId: Scalars['ID']['input'];
};

export type LibraryItem = {
  createdAt: Scalars['DateTime']['output'];
  editUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isSaved: Scalars['Boolean']['output'];
  openedAt?: Maybe<Scalars['DateTime']['output']>;
  permissions?: Maybe<UserPermissions>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  styles?: Maybe<LibraryItemStyles>;
  timeZone: Scalars['TimeZone']['output'];
  title: Scalars['String']['output'];
  type: LibraryItemType;
};

export type LibraryItemStyles = {
  bold: Scalars['Int']['output'];
  hue: Scalars['Int']['output'];
  lightness: Scalars['Int']['output'];
  saturation: Scalars['Int']['output'];
};

export enum LibraryItemType {
  CollaborativeCalendar = 'COLLABORATIVE_CALENDAR',
  Page = 'PAGE',
}

export type ListExperienceEventInput = {
  endDate?: InputMaybe<Scalars['DateOnly']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateOnly']['input']>;
};

export type ListExperienceInteractionSlotInput = {
  blockId?: InputMaybe<Scalars['String']['input']>;
  eventIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Location = {
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LocationInput = {
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum LogoOptions {
  None = 'NONE',
  Square = 'SQUARE',
}

export type MagicJob = {
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  file?: Maybe<MagicJobFile>;
  id: Scalars['ID']['output'];
  prompt?: Maybe<Scalars['String']['output']>;
  source: MagicJobSource;
  sourceText?: Maybe<Scalars['String']['output']>;
  status: MagicJobStatus;
  text?: Maybe<Scalars['String']['output']>;
  timeZone: Scalars['TimeZone']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: MagicJobType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MagicJobFile = {
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum MagicJobSource {
  App = 'APP',
  Email = 'EMAIL',
  Web = 'WEB',
}

export enum MagicJobStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Delayed = 'DELAYED',
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Waiting = 'WAITING',
}

export enum MagicJobType {
  File = 'FILE',
  Text = 'TEXT',
}

export type Member = {
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['PhoneNumber']['output'];
};

export enum MemberFieldType {
  PhoneNumber = 'PHONE_NUMBER',
  Text = 'TEXT',
}

export type MemberForm = {
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<MemberFormField>;
  id: Scalars['ID']['output'];
  scheduleId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type MemberFormField = {
  formId: Scalars['ID']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  type: MemberFieldType;
};

export type MemberFormFieldData = {
  fieldId: Scalars['ID']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MemberFormFieldDataInput = {
  fieldId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MemberInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['PhoneNumber']['input'];
};

export enum MessageStatusType {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
}

export type Mutation = {
  _empty?: Maybe<Scalars['String']['output']>;
  addExperiencePermissions?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Use addSourceToSchedule instead */
  addFeedToSchedule: Feed;
  addMembers: Array<Member>;
  addSchedulePermissions?: Maybe<Scalars['Void']['output']>;
  addSourceToSchedule: FeedSummary;
  changePassword?: Maybe<Scalars['Void']['output']>;
  completeMagicJob: MagicJob;
  completeOnboarding?: Maybe<Scalars['Void']['output']>;
  configureInteractionApp: InteractionApp;
  copyPublicSchedule: Scalars['ID']['output'];
  copySchedule: Scalars['ID']['output'];
  createCategory: Category;
  createEntriesJobFromFile?: Maybe<EntriesJob>;
  createEntriesJobFromPrompt?: Maybe<EntriesJob>;
  createEntry: Entry;
  createEntryAttachment?: Maybe<EntryAttachment>;
  createEntryMessage: EntryMessage;
  createEntryMessages: Array<EntryMessage>;
  createEvent: Event;
  createEvents: Array<Event>;
  createExperience: Experience;
  createLabel: Label;
  createMagicJob: MagicJob;
  createQuickEntriesJobFromFile: QuickEntriesJob;
  createQuickEntriesJobFromPrompt: QuickEntriesJob;
  createSchedule: Schedule;
  createScheduleFromFeed: Schedule;
  createScheduleFromQuickEntriesJob: Schedule;
  createScheduleFromTemplate: Schedule;
  createScheduleJobFromPrompt: ScheduleJob;
  createSnippet: Snippet;
  deleteCategory?: Maybe<Scalars['Void']['output']>;
  deleteEntry?: Maybe<Scalars['Void']['output']>;
  deleteEntryMessage?: Maybe<Scalars['Void']['output']>;
  deleteEvent?: Maybe<Scalars['Void']['output']>;
  deleteExperience?: Maybe<Scalars['Void']['output']>;
  deleteFeedSecret?: Maybe<Scalars['Void']['output']>;
  deleteIntegration?: Maybe<Scalars['Void']['output']>;
  deleteLabel?: Maybe<Scalars['Void']['output']>;
  deleteQuickEntry?: Maybe<Scalars['Void']['output']>;
  deleteSchedule?: Maybe<Scalars['Void']['output']>;
  deleteSnippet?: Maybe<Scalars['Void']['output']>;
  exportEvents: Array<EventExport>;
  exportQuickEntries: Array<QuickEntryExport>;
  followEntry?: Maybe<Scalars['Void']['output']>;
  generateFeedSecret: Scalars['String']['output'];
  importMagicJob: Experience;
  installInteractionApp: Experience;
  leaveExperience?: Maybe<Scalars['Void']['output']>;
  leaveSchedule?: Maybe<Scalars['Void']['output']>;
  login: User;
  logout?: Maybe<Scalars['Void']['output']>;
  publishJobEntries?: Maybe<Scalars['Void']['output']>;
  refreshFeed?: Maybe<Feed>;
  register: User;
  removeEntryAttachment?: Maybe<Scalars['Void']['output']>;
  removeExperiencePermission?: Maybe<Scalars['Void']['output']>;
  removeFeedFromSchedule?: Maybe<Scalars['Void']['output']>;
  removeImageForExperience?: Maybe<Scalars['Void']['output']>;
  removeMember?: Maybe<Scalars['Void']['output']>;
  removeProfileImage?: Maybe<Scalars['Void']['output']>;
  removeSchedulePermission?: Maybe<Scalars['Void']['output']>;
  requestAccess?: Maybe<Scalars['Void']['output']>;
  requestPasswordReset?: Maybe<Scalars['Void']['output']>;
  requestScheduleAccess?: Maybe<Scalars['Void']['output']>;
  resetPassword: User;
  reviewEntriesJob: EntriesJob;
  reviewQuickEntriesJob: QuickEntriesJob;
  rsvp?: Maybe<Scalars['Void']['output']>;
  saveQuickEntriesJob?: Maybe<Scalars['Void']['output']>;
  sendEvent?: Maybe<Scalars['Void']['output']>;
  sendQuickEntry?: Maybe<Scalars['Void']['output']>;
  setExperienceTheme: Experience;
  setInteractionSlot: InteractionSlot;
  setPreferredPlatform: User;
  setupSchedule: Schedule;
  submitAvailability: AvailabilityResponse;
  submitMemberForm?: Maybe<Array<MemberFormFieldData>>;
  subscribeToEvent?: Maybe<Scalars['Void']['output']>;
  suggestTextMessage: Scalars['String']['output'];
  toggleExperienceSave?: Maybe<Scalars['Void']['output']>;
  toggleFeedSecret?: Maybe<Scalars['Void']['output']>;
  toggleQuickEntriesJobSave?: Maybe<Scalars['Void']['output']>;
  toggleRsvp?: Maybe<Scalars['Void']['output']>;
  toggleScheduleSave?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Use updateSchedule instead */
  toggleScheduleSecret?: Maybe<Scalars['Void']['output']>;
  transferExperienceOwnership?: Maybe<Scalars['Void']['output']>;
  transferOwnership?: Maybe<Scalars['Void']['output']>;
  unfollowEntry?: Maybe<Scalars['Void']['output']>;
  unsetInteractionSlot: Scalars['Void']['output'];
  updateCategory: Category;
  updateCategorySortOrder?: Maybe<Scalars['Void']['output']>;
  updateEntry: Entry;
  updateEntryMessage: EntryMessage;
  updateEvent: Event;
  updateExperience: Experience;
  updateExperiencePermission?: Maybe<Scalars['Void']['output']>;
  updateExperienceSlug?: Maybe<Scalars['Void']['output']>;
  updateFeedForSchedule: Feed;
  updateLabel: Label;
  updateLabelSortOrder?: Maybe<Scalars['Void']['output']>;
  updateMember: Member;
  updateProfile: User;
  /** @deprecated No longer used */
  updateQuickEntriesJob: QuickEntriesJob;
  updateQuickEntry: QuickEntry;
  updateSchedule: Schedule;
  updateSchedulePermission?: Maybe<Scalars['Void']['output']>;
  updateSnippet: Snippet;
  updateSnippetVisibility?: Maybe<Scalars['Void']['output']>;
  uploadImageForExperience: Scalars['String']['output'];
  uploadProfileImage: Scalars['String']['output'];
};

export type MutationAddExperiencePermissionsArgs = {
  input: AddExperiencePermissionsInput;
};

export type MutationAddFeedToScheduleArgs = {
  feedUrl?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['ID']['input'];
  syncCutoff?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAddMembersArgs = {
  members: Array<MemberInput>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationAddSchedulePermissionsArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['ID']['input'];
  users: Array<UserPermissionInput>;
};

export type MutationAddSourceToScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
  syncCutoff?: InputMaybe<Scalars['Int']['input']>;
  uri: Scalars['String']['input'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  updatedPassword: Scalars['String']['input'];
};

export type MutationCompleteMagicJobArgs = {
  input: CompleteMagicJobInput;
};

export type MutationConfigureInteractionAppArgs = {
  input: InteractionAppConfigInput;
};

export type MutationCopyPublicScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationCopyScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationCreateCategoryArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  color: Scalars['String']['input'];
  scheduleId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationCreateEntriesJobFromFileArgs = {
  base64File?: InputMaybe<Base64FileInput>;
  createDrafts?: InputMaybe<Scalars['Boolean']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationCreateEntriesJobFromPromptArgs = {
  createDrafts?: InputMaybe<Scalars['Boolean']['input']>;
  prompt: Scalars['String']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationCreateEntryArgs = {
  input: CreateEntryInput;
};

export type MutationCreateEntryAttachmentArgs = {
  entryId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationCreateEntryMessageArgs = {
  body: Scalars['String']['input'];
  deliveryTimeType: DeliveryTimeType;
  entryId: Scalars['ID']['input'];
  entryMessageId?: InputMaybe<Scalars['ID']['input']>;
  fixedDeliveryTime?: InputMaybe<Scalars['DateTime']['input']>;
  fixedTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  hybridDeliveryTime?: InputMaybe<Scalars['LocalTime']['input']>;
  hybridRelativeTime?: InputMaybe<HybridRelativeTime>;
  hybridTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  instance: Scalars['DateTime']['input'];
  recipients?: InputMaybe<Array<Scalars['ID']['input']>>;
  relativeTimeToEntryStart?: InputMaybe<Scalars['Int']['input']>;
  relativeTimeToEntryStartDirection?: InputMaybe<TimeDirection>;
  relativeTimeToEntryStartUnit?: InputMaybe<TimeUnit>;
  scheduleId: Scalars['ID']['input'];
  sendToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateEntryMessagesArgs = {
  entryId: Scalars['ID']['input'];
  messages: Array<EntryMessageInput>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateEventsArgs = {
  input: CreateEventsInput;
};

export type MutationCreateExperienceArgs = {
  input: CreateExperienceInput;
};

export type MutationCreateLabelArgs = {
  labelId?: InputMaybe<Scalars['ID']['input']>;
  scheduleId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  type: LabelType;
};

export type MutationCreateMagicJobArgs = {
  input: CreateMagicJobInput;
};

export type MutationCreateQuickEntriesJobFromFileArgs = {
  base64File?: InputMaybe<Base64FileInput>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<QuickEntriesJobSource>;
  timeZone: Scalars['TimeZone']['input'];
  version?: InputMaybe<QuickEntriesJobVersion>;
};

export type MutationCreateQuickEntriesJobFromPromptArgs = {
  prompt: Scalars['String']['input'];
  source?: InputMaybe<QuickEntriesJobSource>;
  timeZone: Scalars['TimeZone']['input'];
  version?: InputMaybe<QuickEntriesJobVersion>;
};

export type MutationCreateScheduleArgs = {
  headerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  headerId?: InputMaybe<ScheduleHeaderId>;
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<ScheduleType>;
};

export type MutationCreateScheduleFromFeedArgs = {
  feedUrl: Scalars['String']['input'];
  syncCutoff?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateScheduleFromQuickEntriesJobArgs = {
  quickEntriesJobId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateScheduleFromTemplateArgs = {
  templateId: Scalars['String']['input'];
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type MutationCreateScheduleJobFromPromptArgs = {
  prompt: Scalars['String']['input'];
  timeZone: Scalars['TimeZone']['input'];
  type?: InputMaybe<ScheduleType>;
};

export type MutationCreateSnippetArgs = {
  categories: Array<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  feeds?: InputMaybe<Array<Scalars['ID']['input']>>;
  labels: Array<LabelIdInput>;
  name: Scalars['String']['input'];
  scheduleId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  whoLabels: Array<LabelIdInput>;
};

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteEntryArgs = {
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteEntryMessageArgs = {
  entryMessageId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};

export type MutationDeleteExperienceArgs = {
  input: DeleteExperienceInput;
};

export type MutationDeleteFeedSecretArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteLabelArgs = {
  labelId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteQuickEntryArgs = {
  quickEntriesJobId: Scalars['ID']['input'];
  quickEntryId: Scalars['ID']['input'];
};

export type MutationDeleteScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationDeleteSnippetArgs = {
  scheduleId: Scalars['ID']['input'];
  snippetId: Scalars['ID']['input'];
};

export type MutationExportEventsArgs = {
  input: ExportEventsInput;
};

export type MutationExportQuickEntriesArgs = {
  calendarId: Scalars['String']['input'];
  integrationId: Scalars['ID']['input'];
  quickEntryIds: Array<Scalars['ID']['input']>;
};

export type MutationFollowEntryArgs = {
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationGenerateFeedSecretArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationImportMagicJobArgs = {
  input: ImportMagicJobInput;
};

export type MutationInstallInteractionAppArgs = {
  input: InstallInteractionAppInput;
};

export type MutationLeaveExperienceArgs = {
  input: LeaveExperienceInput;
};

export type MutationLeaveScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
  email: Scalars['LowercaseString']['input'];
  password: Scalars['String']['input'];
};

export type MutationPublishJobEntriesArgs = {
  entriesJobId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationRefreshFeedArgs = {
  feedId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationRegisterArgs = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['LowercaseString']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type MutationRemoveEntryAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationRemoveExperiencePermissionArgs = {
  input: RemoveExperiencePermissionInput;
};

export type MutationRemoveFeedFromScheduleArgs = {
  feedId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationRemoveImageForExperienceArgs = {
  input: RemoveImageForExperienceInput;
};

export type MutationRemoveMemberArgs = {
  memberId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationRemoveSchedulePermissionArgs = {
  scheduleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRequestAccessArgs = {
  input: RequestAccessInput;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['LowercaseString']['input'];
};

export type MutationRequestScheduleAccessArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationReviewEntriesJobArgs = {
  entriesJobId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationReviewQuickEntriesJobArgs = {
  quickEntriesJobId: Scalars['ID']['input'];
  timeZone: Scalars['TimeZone']['input'];
};

export type MutationRsvpArgs = {
  entryId: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['ID']['input'];
  status?: InputMaybe<AttendeeStatus>;
};

export type MutationSaveQuickEntriesJobArgs = {
  input: SaveQuickEntriesJobInput;
};

export type MutationSendEventArgs = {
  input: SendEventInput;
};

export type MutationSendQuickEntryArgs = {
  email: Scalars['LowercaseString']['input'];
  quickEntriesJobId?: InputMaybe<Scalars['ID']['input']>;
  quickEntryId: Scalars['ID']['input'];
};

export type MutationSetExperienceThemeArgs = {
  input: SetExperienceThemeInput;
};

export type MutationSetInteractionSlotArgs = {
  input: SetInteractionSlotInput;
};

export type MutationSetPreferredPlatformArgs = {
  preferredPlatform?: InputMaybe<PlatformTypes>;
};

export type MutationSetupScheduleArgs = {
  categories?: InputMaybe<Array<CategoryInput>>;
  labels?: InputMaybe<Array<LabelInput>>;
  members?: InputMaybe<Array<MemberInput>>;
  scheduleId: Scalars['ID']['input'];
  whoLabels?: InputMaybe<Array<LabelInput>>;
};

export type MutationSubmitAvailabilityArgs = {
  response: AvailabilityResponseInput;
  scheduleId: Scalars['ID']['input'];
};

export type MutationSubmitMemberFormArgs = {
  formData: Array<MemberFormFieldDataInput>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationSubscribeToEventArgs = {
  input: SubscribeToEventInput;
};

export type MutationSuggestTextMessageArgs = {
  input: SuggestTextMessageInput;
};

export type MutationToggleExperienceSaveArgs = {
  input: ToggleExperienceSaveInput;
};

export type MutationToggleFeedSecretArgs = {
  enabled: Scalars['Boolean']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationToggleQuickEntriesJobSaveArgs = {
  quickEntriesJobId: Scalars['ID']['input'];
  saved: Scalars['Boolean']['input'];
};

export type MutationToggleRsvpArgs = {
  enabled: Scalars['Boolean']['input'];
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationToggleScheduleSaveArgs = {
  saved: Scalars['Boolean']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationToggleScheduleSecretArgs = {
  enabled: Scalars['Boolean']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationTransferExperienceOwnershipArgs = {
  input: TransferExperienceOwnershipInput;
};

export type MutationTransferOwnershipArgs = {
  scheduleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUnfollowEntryArgs = {
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type MutationUnsetInteractionSlotArgs = {
  input: UnsetInteractionSlotInput;
};

export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  scheduleId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCategorySortOrderArgs = {
  categories: Array<CategorySortOrderInput>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationUpdateEntryArgs = {
  input: UpdateEntryInput;
};

export type MutationUpdateEntryMessageArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  deliveryTimeType: DeliveryTimeType;
  entryMessageId: Scalars['ID']['input'];
  fixedDeliveryTime?: InputMaybe<Scalars['DateTime']['input']>;
  fixedTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  hybridDeliveryTime?: InputMaybe<Scalars['LocalTime']['input']>;
  hybridRelativeTime?: InputMaybe<HybridRelativeTime>;
  hybridTimeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  instance: Scalars['DateTime']['input'];
  recipients?: InputMaybe<Array<Scalars['ID']['input']>>;
  relativeTimeToEntryStart?: InputMaybe<Scalars['Int']['input']>;
  relativeTimeToEntryStartDirection?: InputMaybe<TimeDirection>;
  relativeTimeToEntryStartUnit?: InputMaybe<TimeUnit>;
  scheduleId: Scalars['ID']['input'];
  sendToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type MutationUpdateExperienceArgs = {
  input: UpdateExperienceInput;
};

export type MutationUpdateExperiencePermissionArgs = {
  input: UpdateExperiencePermissionInput;
};

export type MutationUpdateExperienceSlugArgs = {
  input: UpdateExperienceSlugInput;
};

export type MutationUpdateFeedForScheduleArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  feedId: Scalars['ID']['input'];
  labels?: InputMaybe<Array<LabelIdInput>>;
  scheduleId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  whoLabels?: InputMaybe<Array<LabelIdInput>>;
};

export type MutationUpdateLabelArgs = {
  labelId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type MutationUpdateLabelSortOrderArgs = {
  labels: Array<LabelSortOrderInput>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationUpdateMemberArgs = {
  memberId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>;
  scheduleId: Scalars['ID']['input'];
};

export type MutationUpdateProfileArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateQuickEntriesJobArgs = {
  input: UpdateQuickEntriesJobInput;
};

export type MutationUpdateQuickEntryArgs = {
  input: UpdateQuickEntryInput;
};

export type MutationUpdateScheduleArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  headerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  headerId?: InputMaybe<ScheduleHeaderId>;
  headerType?: InputMaybe<ScheduleHeaderType>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  rsvpSettings?: InputMaybe<RsvpSettingsInput>;
  scheduleId: Scalars['ID']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  socialSharingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  views?: InputMaybe<Array<InputMaybe<ScheduleViewInput>>>;
};

export type MutationUpdateSchedulePermissionArgs = {
  role: Role;
  scheduleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUpdateSnippetArgs = {
  categories: Array<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  feeds?: InputMaybe<Array<Scalars['ID']['input']>>;
  labels: Array<LabelIdInput>;
  name: Scalars['String']['input'];
  scheduleId: Scalars['ID']['input'];
  snippetId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  whoLabels: Array<LabelIdInput>;
};

export type MutationUpdateSnippetVisibilityArgs = {
  scheduleId: Scalars['ID']['input'];
  snippetId: Scalars['ID']['input'];
  visibility: Visibility;
};

export type MutationUploadImageForExperienceArgs = {
  input: UploadImageForExperienceInput;
};

export type MutationUploadProfileImageArgs = {
  image: Scalars['Upload']['input'];
};

export enum OwnerPermissions {
  AddToCalendar = 'ADD_TO_CALENDAR',
  EntryCreate = 'ENTRY_CREATE',
  EntryDelete = 'ENTRY_DELETE',
  EntryUpdate = 'ENTRY_UPDATE',
  Messages = 'MESSAGES',
  PermissionsModify = 'PERMISSIONS_MODIFY',
  Rsvp = 'RSVP',
  ScheduleCopy = 'SCHEDULE_COPY',
  ScheduleDelete = 'SCHEDULE_DELETE',
  ScheduleExport = 'SCHEDULE_EXPORT',
  ScheduleImport = 'SCHEDULE_IMPORT',
  ScheduleModifySettings = 'SCHEDULE_MODIFY_SETTINGS',
  ScheduleRename = 'SCHEDULE_RENAME',
  ScheduleView = 'SCHEDULE_VIEW',
  SnippetEdit = 'SNIPPET_EDIT',
  SnippetView = 'SNIPPET_VIEW',
}

export enum PlatformTypes {
  AgendaHero = 'AGENDA_HERO',
  AppleIcs = 'APPLE_ICS',
  Email = 'EMAIL',
  GoogleIntegration = 'GOOGLE_INTEGRATION',
  GoogleLink = 'GOOGLE_LINK',
  OfficeLink = 'OFFICE_LINK',
  OtherIcs = 'OTHER_ICS',
  OutlookLink = 'OUTLOOK_LINK',
  YahooLink = 'YAHOO_LINK',
}

export type PreviewMagicExperienceInput = {
  magicJobId: Scalars['String']['input'];
};

export enum PublicPermissions {
  AddToCalendar = 'ADD_TO_CALENDAR',
}

export type PublicUser = {
  avatar: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['LowercaseString']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: Status;
};

export type Query = {
  _empty?: Maybe<Scalars['String']['output']>;
  checkSlugAvailability: Scalars['Boolean']['output'];
  getAuthAction: AuthAction;
  getAvailabilityResponses: Array<AvailabilityResponse>;
  getEntriesJobForSchedule: EntriesJob;
  getEntriesJobsForSchedule: Array<EntriesJob>;
  getEntry: Entry;
  getEntryMessages: Array<EntryMessage>;
  getExperience: Experience;
  getExperiencePermissions: UserPermissions;
  getExperienceThemes: Array<ExperienceTheme>;
  getFeedForSchedule: Feed;
  /** @deprecated Use getIcsFeeds instead */
  getFeeds: Array<Feed>;
  /** @deprecated Use the feeds property under getSchedule instead */
  getFeedsForSchedule: Array<FeedSummary>;
  getIcsFeeds: Array<FeedSummary>;
  getIntegrations: Array<Integration>;
  getInternalFeeds: Array<FeedSummary>;
  getLayout: LayoutDefinition;
  getLibrary: Array<LibraryItem>;
  getMagicJob: MagicJob;
  getMemberForm: MemberForm;
  getProfile: User;
  getPublishedExperience: Experience;
  getQuickEntriesJob: QuickEntriesJob;
  getQuickEntriesJobs: Array<QuickEntriesJob>;
  getQuickEntriesPage: QuickEntriesPage;
  getSchedule: Schedule;
  getScheduleHeaderImages: Array<ScheduleHeaderImage>;
  getScheduleJob: ScheduleJob;
  getScheduleTemplates: Array<ScheduleTemplate>;
  getSchedules: Array<Schedule>;
  getSnippet: Snippet;
  getSnippets: Array<Snippet>;
  previewMagicExperience: Experience;
  verifyPasswordReset?: Maybe<Scalars['Void']['output']>;
};

export type QueryCheckSlugAvailabilityArgs = {
  input: CheckSlugAvailabilityInput;
};

export type QueryGetAuthActionArgs = {
  email: Scalars['LowercaseString']['input'];
};

export type QueryGetAvailabilityResponsesArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetEntriesJobForScheduleArgs = {
  entriesJobId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetEntriesJobsForScheduleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  scheduleId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<JobStatus>>;
};

export type QueryGetEntryArgs = {
  entryId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetEntryMessagesArgs = {
  scheduleId: Scalars['ID']['input'];
  sortDirection?: InputMaybe<SortDirection>;
};

export type QueryGetExperienceArgs = {
  input: GetExperienceInput;
};

export type QueryGetExperiencePermissionsArgs = {
  input: GetExperiencePermissionsInput;
};

export type QueryGetExperienceThemesArgs = {
  input: GetExperienceThemesInput;
};

export type QueryGetFeedForScheduleArgs = {
  feedId: Scalars['ID']['input'];
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetFeedsForScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetIcsFeedsArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetInternalFeedsArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetLayoutArgs = {
  input: GetLayoutInput;
};

export type QueryGetMagicJobArgs = {
  input: GetMagicJobInput;
};

export type QueryGetMemberFormArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetPublishedExperienceArgs = {
  input: GetPublishedExperienceInput;
};

export type QueryGetQuickEntriesJobArgs = {
  quickEntriesJobId: Scalars['ID']['input'];
};

export type QueryGetQuickEntriesPageArgs = {
  by?: InputMaybe<QuickEntriesPageBy>;
};

export type QueryGetScheduleArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryGetScheduleJobArgs = {
  scheduleJobId: Scalars['ID']['input'];
};

export type QueryGetSnippetArgs = {
  snippetId: Scalars['ID']['input'];
};

export type QueryGetSnippetsArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type QueryPreviewMagicExperienceArgs = {
  input: PreviewMagicExperienceInput;
};

export type QueryVerifyPasswordResetArgs = {
  token: Scalars['String']['input'];
};

export type QuickEntriesJob = {
  convertedFromMagicJob?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  file?: Maybe<QuickEntriesJobFile>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<QuickEntriesJobMetadata>;
  prompt?: Maybe<Scalars['String']['output']>;
  quickEntries: Array<QuickEntry>;
  scheduleId?: Maybe<Scalars['ID']['output']>;
  source: QuickEntriesJobSource;
  sourcePrompt?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  timeZone: Scalars['TimeZone']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: QuickEntriesJobType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type QuickEntriesJobFile = {
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  mimetype: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type QuickEntriesJobMetadata = {
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum QuickEntriesJobSource {
  App = 'APP',
  Email = 'EMAIL',
  Web = 'WEB',
}

export enum QuickEntriesJobType {
  File = 'FILE',
  Prompt = 'PROMPT',
}

export enum QuickEntriesJobVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type QuickEntriesPage = {
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  quickEntries: Array<QuickEntry>;
  socialSharingEnabled: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type QuickEntriesPageBy = {
  quickEntriesJobId?: InputMaybe<Scalars['ID']['input']>;
  quickEntriesJobSlug?: InputMaybe<Scalars['String']['input']>;
  scheduleId?: InputMaybe<Scalars['ID']['input']>;
  scheduleSlug?: InputMaybe<Scalars['String']['input']>;
};

export type QuickEntry = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationWithPlace?: Maybe<Location>;
  recurrences: Array<Recurrence>;
  timeZone: Scalars['TimeZone']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type QuickEntryExport = {
  calendarId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entryId?: Maybe<Scalars['ID']['output']>;
  externalId: Scalars['String']['output'];
  externalLink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationId?: Maybe<Scalars['ID']['output']>;
  quickEntryId?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type Recurrence = {
  endDate: Scalars['DateTime']['output'];
  endDay?: Maybe<Scalars['DateOnly']['output']>;
  isOnDay: Scalars['Boolean']['output'];
  localEndTime?: Maybe<Scalars['LocalTime']['output']>;
  localOffsetName?: Maybe<Scalars['String']['output']>;
  localStartTime?: Maybe<Scalars['LocalTime']['output']>;
  rule?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  startDay?: Maybe<Scalars['DateOnly']['output']>;
};

export type RecurrenceInput = {
  endDate: Scalars['DateTime']['input'];
  isOnDay: Scalars['Boolean']['input'];
  rule?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type RemoveExperiencePermissionInput = {
  experienceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveImageForExperienceInput = {
  experienceId: Scalars['String']['input'];
  type: ExperienceImageTypes;
};

export type RequestAccessInput = {
  feature: Feature;
};

export enum Role {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Public = 'PUBLIC',
  Viewer = 'VIEWER',
}

export type RolePermissions = {
  editor: Array<EditorPermissions>;
  owner: Array<OwnerPermissions>;
  public: Array<PublicPermissions>;
  viewer: Array<ViewerPermissions>;
};

export type RsvpInfo = {
  notes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AttendeeStatus>;
};

export type RsvpSettings = {
  noLabel?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  yesLabel?: Maybe<Scalars['String']['output']>;
};

export type RsvpSettingsInput = {
  noLabel?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  yesLabel?: InputMaybe<Scalars['String']['input']>;
};

export type SaveQuickEntriesJobInput = {
  id: Scalars['ID']['input'];
};

export type Schedule = {
  activeUsers: Array<Scalars['ID']['output']>;
  categories: Array<Category>;
  createdAt: Scalars['DateTime']['output'];
  cta?: Maybe<ScheduleCta>;
  description?: Maybe<Scalars['String']['output']>;
  entries: Array<Entry>;
  feedSecret: FeedSecret;
  feeds: Array<FeedSummary>;
  firstEntryStartDate?: Maybe<Scalars['DateTime']['output']>;
  hasBeenSetup: Scalars['Boolean']['output'];
  hasEntries: Scalars['Boolean']['output'];
  header: ScheduleHeader;
  id: Scalars['ID']['output'];
  isCopyable: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSaved: Scalars['Boolean']['output'];
  labels: Array<Label>;
  limits: ScheduleLimits;
  memberForm?: Maybe<MemberForm>;
  members?: Maybe<Array<Member>>;
  openedAt?: Maybe<Scalars['DateTime']['output']>;
  permissions?: Maybe<UserPermissions>;
  role: Role;
  rsvpSettings?: Maybe<RsvpSettings>;
  /** @deprecated Use isPublic instead */
  secret: ScheduleSecret;
  selfMemberFormSubmission?: Maybe<Array<MemberFormFieldData>>;
  slug?: Maybe<Scalars['String']['output']>;
  socialSharingEnabled: Scalars['Boolean']['output'];
  timeZone: Scalars['TimeZone']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: ScheduleType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  views: Array<ScheduleView>;
  whoLabels: Array<Label>;
};

export type ScheduleEntriesArgs = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  feeds?: InputMaybe<Array<Scalars['ID']['input']>>;
  labels?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  snippets?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  whoLabels?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ScheduleCta = {
  link?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ScheduleHeader = {
  enabled: Scalars['Boolean']['output'];
  id?: Maybe<ScheduleHeaderId>;
  imageBaseUrl?: Maybe<Scalars['String']['output']>;
  type: ScheduleHeaderType;
};

export enum ScheduleHeaderId {
  Acting_1 = 'ACTING_1',
  Agendahero_1 = 'AGENDAHERO_1',
  Archery_1 = 'ARCHERY_1',
  Astronomy_1 = 'ASTRONOMY_1',
  Baking_1 = 'BAKING_1',
  Baseball_1 = 'BASEBALL_1',
  Basketball_1 = 'BASKETBALL_1',
  Beach_1 = 'BEACH_1',
  BeachVolleyball_1 = 'BEACH_VOLLEYBALL_1',
  Billiards_1 = 'BILLIARDS_1',
  Biology_1 = 'BIOLOGY_1',
  Birds_1 = 'BIRDS_1',
  Birthday_1 = 'BIRTHDAY_1',
  Books_1 = 'BOOKS_1',
  Bowling_1 = 'BOWLING_1',
  Boxing_1 = 'BOXING_1',
  Business_1 = 'BUSINESS_1',
  Camping_1 = 'CAMPING_1',
  Camping_2 = 'CAMPING_2',
  Canyon_1 = 'CANYON_1',
  Cards_1 = 'CARDS_1',
  Carpool_1 = 'CARPOOL_1',
  Cat_1 = 'CAT_1',
  Chemistry_1 = 'CHEMISTRY_1',
  ChessClub_1 = 'CHESS_CLUB_1',
  Cycling_1 = 'CYCLING_1',
  Dancing_1 = 'DANCING_1',
  DebateTeam_1 = 'DEBATE_TEAM_1',
  Doctor_1 = 'DOCTOR_1',
  Dog_1 = 'DOG_1',
  Dog_2 = 'DOG_2',
  Electronics_1 = 'ELECTRONICS_1',
  Fall_1 = 'FALL_1',
  Family_1 = 'FAMILY_1',
  FieldHockey_1 = 'FIELD_HOCKEY_1',
  Fishing_1 = 'FISHING_1',
  Fish_1 = 'FISH_1',
  Football_1 = 'FOOTBALL_1',
  Garbage_1 = 'GARBAGE_1',
  Gardening_1 = 'GARDENING_1',
  Gliding_1 = 'GLIDING_1',
  Golf_1 = 'GOLF_1',
  Golf_2 = 'GOLF_2',
  Graduation_1 = 'GRADUATION_1',
  Groceries_1 = 'GROCERIES_1',
  Gymnastics_1 = 'GYMNASTICS_1',
  Hamster_1 = 'HAMSTER_1',
  HappyBikeStroll_1 = 'HAPPY_BIKE_STROLL_1',
  HighDive_1 = 'HIGH_DIVE_1',
  Hiking_1 = 'HIKING_1',
  Hiking_2 = 'HIKING_2',
  Homework_1 = 'HOMEWORK_1',
  Horses_1 = 'HORSES_1',
  IceHockey_1 = 'ICE_HOCKEY_1',
  IceSkating_1 = 'ICE_SKATING_1',
  Instruments_1 = 'INSTRUMENTS_1',
  Karate_1 = 'KARATE_1',
  Lacrosse_1 = 'LACROSSE_1',
  Lake_1 = 'LAKE_1',
  LawClub_1 = 'LAW_CLUB_1',
  Lion_1 = 'LION_1',
  London_1 = 'LONDON_1',
  LosAngeles_1 = 'LOS_ANGELES_1',
  Meeting_1 = 'MEETING_1',
  NewYork_1 = 'NEW_YORK_1',
  Paris_1 = 'PARIS_1',
  Park_1 = 'PARK_1',
  Pets_1 = 'PETS_1',
  Photography_1 = 'PHOTOGRAPHY_1',
  Piano_1 = 'PIANO_1',
  PingPong_1 = 'PING_PONG_1',
  PreSchool_1 = 'PRE_SCHOOL_1',
  PreSchool_2 = 'PRE_SCHOOL_2',
  RoadTrip_1 = 'ROAD_TRIP_1',
  RoboticsClub_1 = 'ROBOTICS_CLUB_1',
  RomeColosseum_1 = 'ROME_COLOSSEUM_1',
  Sailing_1 = 'SAILING_1',
  SanFrancisco_1 = 'SAN_FRANCISCO_1',
  SanFrancisco_2 = 'SAN_FRANCISCO_2',
  School_1 = 'SCHOOL_1',
  Scuba_1 = 'SCUBA_1',
  Singing_1 = 'SINGING_1',
  Skateboarding_1 = 'SKATEBOARDING_1',
  Skiing_1 = 'SKIING_1',
  SkyDiving_1 = 'SKY_DIVING_1',
  Snowboarding_1 = 'SNOWBOARDING_1',
  Soccer_1 = 'SOCCER_1',
  Soccer_2 = 'SOCCER_2',
  Spring_1 = 'SPRING_1',
  Summer_1 = 'SUMMER_1',
  Surfing_1 = 'SURFING_1',
  Surfing_2 = 'SURFING_2',
  Swimming_1 = 'SWIMMING_1',
  Sydney_1 = 'SYDNEY_1',
  Tennis_1 = 'TENNIS_1',
  Theatre_1 = 'THEATRE_1',
  Tokyo_1 = 'TOKYO_1',
  TrackField_1 = 'TRACK_FIELD_1',
  TrackField_2 = 'TRACK_FIELD_2',
  Usa_1 = 'USA_1',
  Usa_2 = 'USA_2',
  Usa_3 = 'USA_3',
  Violin_1 = 'VIOLIN_1',
  Volleyball_1 = 'VOLLEYBALL_1',
  Walk_1 = 'WALK_1',
  WaterPolo_1 = 'WATER_POLO_1',
  Wedding_1 = 'WEDDING_1',
  Winter_1 = 'WINTER_1',
  Workout_1 = 'WORKOUT_1',
  Work_1 = 'WORK_1',
  Zebra_1 = 'ZEBRA_1',
  Zoo_1 = 'ZOO_1',
}

export type ScheduleHeaderImage = {
  id: ScheduleHeaderId;
  imageBaseUrl: Scalars['String']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
};

export enum ScheduleHeaderType {
  Ai = 'AI',
  Default = 'DEFAULT',
}

export type ScheduleJob = {
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  prompt: Scalars['String']['output'];
  scheduleId?: Maybe<Scalars['ID']['output']>;
  status: JobStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScheduleLimits = {
  entryScheduledMessageCount: Scalars['Int']['output'];
  memberCount: Scalars['Int']['output'];
};

export type ScheduleSecret = {
  enabled: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
};

export type ScheduleTemplate = {
  headerBaseUrl: Scalars['String']['output'];
  headerIcons: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ScheduleType {
  Classic = 'CLASSIC',
  Conflict = 'CONFLICT',
  List = 'LIST',
  Magic = 'MAGIC',
}

export type ScheduleView = {
  type: ScheduleViewType;
};

export type ScheduleViewInput = {
  type: ScheduleViewType;
};

export enum ScheduleViewType {
  Agenda = 'AGENDA',
  Month = 'MONTH',
  Summary = 'SUMMARY',
  Week = 'WEEK',
}

export type SendEventInput = {
  email: Scalars['LowercaseString']['input'];
  eventId: Scalars['ID']['input'];
};

export type SetExperienceThemeInput = {
  experienceId: Scalars['ID']['input'];
  themeId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetInteractionSlotInput = {
  blockId: Scalars['String']['input'];
  eventId?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['String']['input'];
  interactionAppId?: InputMaybe<Scalars['String']['input']>;
  slotIndex: Scalars['Int']['input'];
};

export enum SlotSize {
  Md = 'MD',
}

export type Snippet = {
  categories: Array<Category>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  entries: Array<Entry>;
  feedPublicUrl: Scalars['String']['output'];
  feeds: Array<Feed>;
  id: Scalars['ID']['output'];
  labels: Array<Label>;
  name: Scalars['String']['output'];
  scheduleId?: Maybe<Scalars['String']['output']>;
  scheduleTimeZone: Scalars['TimeZone']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  visibility: Visibility;
  whoLabels: Array<Label>;
};

export type SnippetEntriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Status {
  Active = 'ACTIVE',
  Anonymous = 'ANONYMOUS',
  Invited = 'INVITED',
}

export type SubscribeToEventInput = {
  email: Scalars['LowercaseString']['input'];
  eventId: Scalars['ID']['input'];
  experienceId: Scalars['ID']['input'];
};

export type Subscription = {
  _empty?: Maybe<Scalars['String']['output']>;
  entriesAdded: Array<Entry>;
  entryAdded: Entry;
  entryDeleted: Scalars['ID']['output'];
  entryUpdated: Entry;
  scheduleOpened: Scalars['ID']['output'];
  scheduleUpdated: Schedule;
};

export type SubscriptionEntriesAddedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SubscriptionEntryAddedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SubscriptionEntryDeletedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SubscriptionEntryUpdatedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SubscriptionScheduleOpenedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SubscriptionScheduleUpdatedArgs = {
  scheduleId: Scalars['ID']['input'];
};

export type SuggestTextMessageInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  isOnDay: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  timeZone: Scalars['TimeZone']['input'];
  title: Scalars['String']['input'];
};

export enum TimeDirection {
  After = 'AFTER',
  Before = 'BEFORE',
}

export enum TimeUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Weeks = 'WEEKS',
}

export enum TimeZoneDisplay {
  Event = 'EVENT',
  Experience = 'EXPERIENCE',
  Visitor = 'VISITOR',
}

export type ToggleExperienceSaveInput = {
  experienceId: Scalars['ID']['input'];
  saved: Scalars['Boolean']['input'];
};

export type TransferExperienceOwnershipInput = {
  experienceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnsetInteractionSlotInput = {
  blockId: Scalars['String']['input'];
  eventId?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['String']['input'];
  slotIndex: Scalars['Int']['input'];
};

export type UpdateBlockInput = {
  blockId: Scalars['ID']['input'];
  layoutId?: InputMaybe<BlockLayouts>;
};

export type UpdateEntryInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  entryId: Scalars['ID']['input'];
  exclusions?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  hidden?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  inclusions?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  labels?: InputMaybe<Array<LabelIdInput>>;
  locationWithPlace?: InputMaybe<LocationInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  recurrences?: InputMaybe<Array<RecurrenceInput>>;
  scheduleId: Scalars['ID']['input'];
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  whoLabels?: InputMaybe<Array<LabelIdInput>>;
};

export type UpdateEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  end: EventDateInput;
  eventId: Scalars['ID']['input'];
  for?: InputMaybe<EventFor>;
  locationWithPlace?: InputMaybe<LocationInput>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  start: EventDateInput;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExperienceInput = {
  attributionDisplay?: InputMaybe<AttributionOptions>;
  bannerDisplay?: InputMaybe<BannerOptions>;
  blocks?: InputMaybe<Array<UpdateBlockInput>>;
  bold?: InputMaybe<Scalars['Int']['input']>;
  colorType?: InputMaybe<ColorTypes>;
  customAlpha?: InputMaybe<Scalars['Float']['input']>;
  customHue?: InputMaybe<Scalars['Int']['input']>;
  customLightness?: InputMaybe<Scalars['Int']['input']>;
  customSaturation?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  eventCardDateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  eventCardDescriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  eventCardEmojiEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  eventCardLayoutId?: InputMaybe<EventCardLayouts>;
  eventCardLocationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  eventListDisplay?: InputMaybe<EventListDisplay>;
  experienceId: Scalars['ID']['input'];
  hue?: InputMaybe<Scalars['Int']['input']>;
  layoutId?: InputMaybe<ExperienceLayouts>;
  lightness?: InputMaybe<Scalars['Int']['input']>;
  logoDisplay?: InputMaybe<LogoOptions>;
  saturation?: InputMaybe<Scalars['Int']['input']>;
  sparkle?: InputMaybe<Scalars['Int']['input']>;
  subscribeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  timeZoneDisplay?: InputMaybe<TimeZoneDisplay>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExperiencePermissionInput = {
  experienceId: Scalars['ID']['input'];
  role: Role;
  userId: Scalars['ID']['input'];
};

export type UpdateExperienceSlugInput = {
  experienceId: Scalars['String']['input'];
  slug: Scalars['LowercaseString']['input'];
};

export type UpdateQuickEntriesJobInput = {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQuickEntryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locationWithPlace?: InputMaybe<LocationInput>;
  recurrences?: InputMaybe<Array<RecurrenceInput>>;
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSlotInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  interactionAppId?: InputMaybe<Scalars['String']['input']>;
  slotIndex: Scalars['Int']['input'];
};

export type UploadImageForExperienceInput = {
  experienceId: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
  type: ExperienceImageTypes;
};

export type User = {
  avatar: Scalars['String']['output'];
  campaign?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['LowercaseString']['output']>;
  id: Scalars['ID']['output'];
  identityStrategies: Array<IdentityStrategy>;
  name?: Maybe<Scalars['String']['output']>;
  preferredPlatform?: Maybe<PlatformTypes>;
  rolePermissions: RolePermissions;
  schedules: Array<Schedule>;
  settings: Array<UserSetting>;
  status: Status;
  tier: UserTier;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserPermission = {
  avatar: Scalars['String']['output'];
  email?: Maybe<Scalars['LowercaseString']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  role: Role;
};

export type UserPermissionInput = {
  email: Scalars['LowercaseString']['input'];
  role: Role;
};

export type UserPermissions = {
  all: Array<UserPermission>;
  role: Role;
};

export type UserSetting = {
  name: UserSettingName;
  value: Scalars['Mixed']['output'];
};

export enum UserSettingName {
  DidCompleteOnboarding = 'DID_COMPLETE_ONBOARDING',
  EnabledScheduleTypes = 'ENABLED_SCHEDULE_TYPES',
}

export enum UserTier {
  Free = 'FREE',
  Pro = 'PRO',
}

export enum ViewerPermissions {
  AddToCalendar = 'ADD_TO_CALENDAR',
  Rsvp = 'RSVP',
  ScheduleView = 'SCHEDULE_VIEW',
  SnippetView = 'SNIPPET_VIEW',
}

export enum Visibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export const namedOperations = {
  Query: {
    GetAuthAction: 'GetAuthAction',
    GetProfile: 'GetProfile',
    PollMagicJobStatusForAddContentModal:
      'PollMagicJobStatusForAddContentModal',
    GetPageExperienceEvents: 'GetPageExperienceEvents',
    GetPublishedPageExperienceEvents: 'GetPublishedPageExperienceEvents',
    GetPageExperience: 'GetPageExperience',
    GetPublishedPageExperience: 'GetPublishedPageExperience',
    PreviewMagicExperience: 'PreviewMagicExperience',
    PollMagicJobStatus: 'PollMagicJobStatus',
    CheckSlugAvailability: 'CheckSlugAvailability',
    GetExperienceCollaborators: 'GetExperienceCollaborators',
    GetExperienceThemes: 'GetExperienceThemes',
    GetScheduleTemplates: 'GetScheduleTemplates',
    GetSchedules: 'GetSchedules',
    GetLibrary: 'GetLibrary',
    GetQuickEntriesPage: 'GetQuickEntriesPage',
    VerifyResetToken: 'VerifyResetToken',
    PollEntriesJobStatus: 'PollEntriesJobStatus',
    GetScheduleFeed: 'GetScheduleFeed',
    GetEntryInvites: 'GetEntryInvites',
    GetMessagesForEntry: 'GetMessagesForEntry',
    GetScheduleHeaderImages: 'GetScheduleHeaderImages',
    GetScheduleEntries: 'GetScheduleEntries',
    GetSchedule: 'GetSchedule',
    GetIntegrations: 'GetIntegrations',
    GetScheduleCollaborators: 'GetScheduleCollaborators',
    GetScheduleMembers: 'GetScheduleMembers',
    GetEntry: 'GetEntry',
    GetEntryForMessage: 'GetEntryForMessage',
    GetScheduleEntryMessages: 'GetScheduleEntryMessages',
    GetScheduleJob: 'GetScheduleJob',
    GetSnippet: 'GetSnippet',
    GetSnippets: 'GetSnippets',
    GetInternalFeeds: 'GetInternalFeeds',
    GetIcsFeeds: 'GetIcsFeeds',
    GetFeedSource: 'GetFeedSource',
    GetScheduleFeeds: 'GetScheduleFeeds',
    GetAvailabilityResponses: 'GetAvailabilityResponses',
  },
  Mutation: {
    ChangePassword: 'ChangePassword',
    UpdateProfile: 'UpdateProfile',
    Login: 'Login',
    Register: 'Register',
    CreateMagicJobForAddContentModal: 'CreateMagicJobForAddContentModal',
    DeleteEvent: 'DeleteEvent',
    UpdateEvent: 'UpdateEvent',
    CreateEvents: 'CreateEvents',
    CreateExperience: 'CreateExperience',
    DeleteExperience: 'DeleteExperience',
    ImportMagicJob: 'ImportMagicJob',
    RemoveImageForExperience: 'RemoveImageForExperience',
    SetAddToCalendarMode: 'SetAddToCalendarMode',
    ToggleExperienceSave: 'ToggleExperienceSave',
    UpdateExperience: 'UpdateExperience',
    UploadImageForExperience: 'UploadImageForExperience',
    SubscribeToEvent: 'SubscribeToEvent',
    CreateMagicEntry: 'CreateMagicEntry',
    CreateMagicJob: 'CreateMagicJob',
    DeleteMagicEvent: 'DeleteMagicEvent',
    UpdateMagicEvent: 'UpdateMagicEvent',
    CompleteMagicJob: 'CompleteMagicJob',
    AddCollaboratorToExperience: 'AddCollaboratorToExperience',
    LeaveExperience: 'LeaveExperience',
    RemoveExperienceCollaborator: 'RemoveExperienceCollaborator',
    TransferExperienceOwnership: 'TransferExperienceOwnership',
    UpdateExperienceCollaborator: 'UpdateExperienceCollaborator',
    UpdateExperienceSlug: 'UpdateExperienceSlug',
    SetExperienceTheme: 'SetExperienceTheme',
    CreateSchedule: 'CreateSchedule',
    CreateScheduleFromTemplate: 'CreateScheduleFromTemplate',
    DeleteSchedule: 'DeleteSchedule',
    LeaveSchedule: 'LeaveSchedule',
    Logout: 'Logout',
    RemoveIntegration: 'RemoveIntegration',
    RequestAccess: 'RequestAccess',
    SetPreferredPlatform: 'SetPreferredPlatform',
    ExportMagicEvents: 'ExportMagicEvents',
    ExportQuickEntries: 'ExportQuickEntries',
    SendEvent: 'SendEvent',
    SendQuickEntry: 'SendQuickEntry',
    ResetPassword: 'ResetPassword',
    RequestResetPassword: 'RequestResetPassword',
    CreateSnippet: 'CreateSnippet',
    createEntriesJobFromFile: 'createEntriesJobFromFile',
    CreateEntriesJobFromPrompt: 'CreateEntriesJobFromPrompt',
    PublishJobEntries: 'PublishJobEntries',
    AddMember: 'AddMember',
    GenerateScheduleFeedSecret: 'GenerateScheduleFeedSecret',
    ToggleScheduleFeedEnabled: 'ToggleScheduleFeedEnabled',
    CreateCategory: 'CreateCategory',
    DeleteCategory: 'DeleteCategory',
    UpdateCategory: 'UpdateCategory',
    UpdateCategorySortOrder: 'UpdateCategorySortOrder',
    CopyPublicSchedule: 'CopyPublicSchedule',
    SuggestTextMessage: 'SuggestTextMessage',
    ToggleRsvp: 'ToggleRsvp',
    UpdateRsvpSettings: 'UpdateRsvpSettings',
    CreateLabel: 'CreateLabel',
    DeleteLabel: 'DeleteLabel',
    UpdateLabel: 'UpdateLabel',
    UpdateLabelSortOrder: 'UpdateLabelSortOrder',
    SubmitMemberForm: 'SubmitMemberForm',
    SetupSchedule: 'SetupSchedule',
    RSVP: 'RSVP',
    UpdateScheduleHeader: 'UpdateScheduleHeader',
    EnableScheduleHeader: 'EnableScheduleHeader',
    UpdateScheduleSettings: 'UpdateScheduleSettings',
    AddCollaboratorToSchedule: 'AddCollaboratorToSchedule',
    RemoveCollaborator: 'RemoveCollaborator',
    TogglePublicSchedule: 'TogglePublicSchedule',
    TransferOwnership: 'TransferOwnership',
    UpdateCollaborator: 'UpdateCollaborator',
    CopySchedule: 'CopySchedule',
    CreateEntry: 'CreateEntry',
    CreateEntryMessage: 'CreateEntryMessage',
    CreateEntryMessages: 'CreateEntryMessages',
    DeleteEntry: 'DeleteEntry',
    DeleteEntryMessage: 'DeleteEntryMessage',
    FollowEntry: 'FollowEntry',
    RemoveMember: 'RemoveMember',
    RenameSchedule: 'RenameSchedule',
    ToggleScheduleHeaderEnabled: 'ToggleScheduleHeaderEnabled',
    ToggleScheduleSave: 'ToggleScheduleSave',
    UnfollowEntry: 'UnfollowEntry',
    UpdateEntry: 'UpdateEntry',
    UpdateEntryMessage: 'UpdateEntryMessage',
    UpdateSchedule: 'UpdateSchedule',
    CreateScheduleJobFromPrompt: 'CreateScheduleJobFromPrompt',
    UpdateSnippetVisibility: 'UpdateSnippetVisibility',
    DeleteSnippet: 'DeleteSnippet',
    UpdateSnippet: 'UpdateSnippet',
    AddSourceToSchedule: 'AddSourceToSchedule',
    RefreshFeed: 'RefreshFeed',
    RemoveFeedFromSchedule: 'RemoveFeedFromSchedule',
    UpdateFeed: 'UpdateFeed',
    SubmitAvailabilityResponse: 'SubmitAvailabilityResponse',
    RequestScheduleAccess: 'RequestScheduleAccess',
  },
  Subscription: {
    EntriesAdded: 'EntriesAdded',
    EntryAdded: 'EntryAdded',
    EntryDeleted: 'EntryDeleted',
    EntryUpdated: 'EntryUpdated',
    ScheduleOpened: 'ScheduleOpened',
    Schedule: 'Schedule',
  },
  Fragment: {
    ExperienceEvent: 'ExperienceEvent',
    ExperienceJob: 'ExperienceJob',
    MagicEvent: 'MagicEvent',
    PageExperience: 'PageExperience',
    MagicJob: 'MagicJob',
    Theme: 'Theme',
    MinimumEntryFields: 'MinimumEntryFields',
    Entry: 'Entry',
    Profile: 'Profile',
    Schedule: 'Schedule',
    EntriesContextFragment: 'EntriesContextFragment',
    Snippet: 'Snippet',
    Feed: 'Feed',
  },
};

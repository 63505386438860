import type { QueryKey } from '@tanstack/react-query';
import type { DateTime } from 'luxon';
import type { ScheduleFilters } from '@/pages/Schedule/contexts';
import type { SortDirection } from '@/types/gql.generated';

type CreateEntriesQueryKeyDateRange = {
  startDate: string;
  endDate: string;
};

/** Which position in an entries query key is the date range located? */
const ENTRIES_QUERY_KEY_DATE_RANGE_POSITION = 3;

// login/register pages
export const createAuthActionQueryKey = (email: string) => [
  'auth-action',
  email,
];

export const createProfileQueryKey = (): QueryKey => ['profile'];

export const createSchedulesQueryKey = (): QueryKey => ['schedule'];

export const createScheduleQueryKey = (scheduleId: string): QueryKey => [
  'schedule',
  scheduleId,
];

export const createScheduleTemplatesQueryKey = (): QueryKey => [
  'schedule-templates',
];

export const createScheduleJobQueryKey = (jobId: string): QueryKey => [
  'schedule-job',
  jobId,
];

export const createScheduleCollaboratorsQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'collaborators'];

export const createEntriesQueryKey = (
  scheduleId?: string | null,
  dateRange?: CreateEntriesQueryKeyDateRange,
  filters?: ScheduleFilters
): QueryKey => {
  const key: unknown[] = ['schedule', scheduleId, 'entries'];
  if (dateRange) {
    key[ENTRIES_QUERY_KEY_DATE_RANGE_POSITION] = dateRange;
  }
  if (filters) {
    key[ENTRIES_QUERY_KEY_DATE_RANGE_POSITION + 1] = filters;
  }
  return key;
};

/**
 * Break a `queryKey` created by `createEntriesQueryKey` up into parts.
 * Note that this currently only parses the date range.
 */
export const parseCreateEntriesQueryKey = (queryKey: QueryKey) => {
  const isDateRange = (
    value: unknown
  ): value is CreateEntriesQueryKeyDateRange => {
    return (
      typeof value === 'object' &&
      value !== null &&
      'startDate' in value &&
      'endDate' in value
    );
  };
  const dateRange = queryKey[ENTRIES_QUERY_KEY_DATE_RANGE_POSITION];
  if (isDateRange(dateRange)) {
    return { dateRange };
  }
  return { dateRange: null };
};

// Export to calendar modal
export const createScheduleFeedSecretQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'feed-secret'];

// Sources tab home
export const createScheduleSourcesQueryKey = (scheduleId: string): QueryKey => {
  return ['schedule', scheduleId, 'sources'];
};

// Sources tab -> source detail
export const createScheduleSourceQueryKey = (
  scheduleId: string,
  feedId: string | undefined,
  filters?: { startDate: DateTime; endDate: DateTime }
): QueryKey => {
  return [
    'schedule',
    scheduleId,
    'sources',
    {
      feedId,
      ...(filters && {
        startDate: filters.startDate.toISO(),
        endDate: filters.endDate.toISO(),
      }),
    },
  ];
};

// Sources tab -> add source modal
export const createGetInternalFeedsQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'internal-feeds'];

export const createScheduleIcsFeedsQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'ics-feeds'];

export const createVerifyTokenQueryKey = (token?: string): QueryKey => [
  'verify-reset-token',
  token,
];

// Members page & share modal
export const createScheduleMembersQueryKey = (
  scheduleId?: string
): QueryKey => ['schedule', scheduleId, 'members'];

// Edit entry message modal -> entry
// Entry detail page
export const createEntryQueryKey = (
  scheduleId: string,
  entryId: string | undefined
): QueryKey => ['schedule', scheduleId, 'entry', entryId];

// Entry modal -> messages
export const createEntryMessagesQueryKey = (
  scheduleId: string,
  entryId: string
): QueryKey => ['schedule', scheduleId, 'entry', entryId, 'messages'];

// Entry modal -> rsvps
export const createEntryInvitesQueryKey = (
  scheduleId: string,
  entryId: string
): QueryKey => ['schedule', scheduleId, 'entry', entryId, 'invites'];

// Message center
export const createScheduleEntryMessagesQueryKey = (
  scheduleId: string,
  sortDirection?: SortDirection
): QueryKey => {
  const keys = ['schedule', scheduleId, 'entry-messages'];
  if (sortDirection) {
    keys.push(sortDirection);
  }
  return keys;
};

// schedule snippets -> list view
export const createScheduleSnippetsQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'snippets'];

// snippet page
export const createSnippetQueryKey = (
  snippetId?: string | undefined,
  userId?: string | undefined
): QueryKey => {
  const keys = ['snippet'];
  if (snippetId) {
    keys.push(snippetId);
  }
  // userId ensures the query won't be cached with a stale `scheduleId` property
  // when changing auth status while viewing a snippet
  if (userId) {
    keys.push('user', userId);
  }
  return keys;
};

// account modal
export const createIntegrationsQueryKey = (): QueryKey => ['integrations'];

// query to poll for the active entries job status in the add content modal
export const createAddContentJobResultsQueryKey = (
  scheduleId: string,
  entriesJobId?: string | null
): QueryKey => {
  const key = ['schedule', scheduleId, 'add-content', 'results'];
  if (entriesJobId) {
    key.push(entriesJobId);
  }
  return key;
};

// query that lists availability/conflict responses for a conflict schedule
export const createAvailabilityResponsesQueryKey = (
  scheduleId: string
): QueryKey => ['schedule', scheduleId, 'availability'];

export const createClassifyPromptQueryKey = (prompt: string): QueryKey => [
  'classify-prompt',
  prompt,
];

// magic tool
export const createGetMagicJobsQueryKey = (): QueryKey => ['magic', 'jobs'];

export const createPollMagicJobQueryKey = (jobId: string | null): QueryKey => [
  'magic',
  'job',
  jobId,
];

export const createMagicJobPageQueryKey = (
  slugOrId: string | undefined
): QueryKey => ['magic', 'page', slugOrId];

export const createScheduleHeaderImagesQueryKey = (): QueryKey => [
  'schedule',
  'header-images',
];

export const createExperiencesQueryKey = (): QueryKey => ['experiences'];

export const createExperienceQueryKey = (
  idOrSlug: string | undefined
): QueryKey => ['experience', idOrSlug];

export const createExperienceEventsQueryKey = (
  idOrSlug: string | undefined
): QueryKey => ['experience', idOrSlug, 'events'];

export const createLibraryQueryKey = (): QueryKey => ['library'];

export const createExperienceCollaboratorsQueryKey = (
  experienceId: string
): QueryKey => ['experience', experienceId, 'collaborators'];

export const createThemesQueryKey = (experienceId: string): QueryKey => [
  'experience',
  experienceId,
  'themes',
];

export const createSlugAvailabilityKey = (slug: string): QueryKey => [
  'slug-availability',
  slug,
];

export const createExperienceSlugQueryKey = (
  experienceId: string
): QueryKey => ['experience', experienceId, 'slug'];

import { useEffect } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { useAuthPopup } from '@/features/magic/extension/hooks/useAuthPopup';
import { useBroadcastReceiver } from '@/features/magic/extension/hooks/useBroadcast';
import { useIsExtension } from '@/features/magic/hooks/useIsExtension';
import {
  type LaunchOption,
  type LaunchParams,
  useLauncherStore,
} from '@/hooks/useLauncher';
import { getLaunchParams } from '@/hooks/useLauncher/util';
import { useToast } from '@/ui';
import { getAuthUrl } from '../util';

type Arg = {
  launch?: LaunchOption;
  launchParams?: LaunchParams;
  email?: string;
};

export const useExtensionPopup = ({
  launch,
  launchParams = {},
  email,
}: Arg = {}) => {
  const toast = useToast();
  const { currentUser, invalidate: invalidateUser } = useCurrentUserContext();
  const { isExtension } = useIsExtension();
  const { open: openPopup, isBlocked } = useAuthPopup();
  const { setLaunch } = useLauncherStore();

  useEffect(() => {
    if (isBlocked) {
      toast.error(
        'We tried to connect to Google Calendar in a popup, but it was blocked. Please enable popups and try again.'
      );
    }
  }, [isBlocked, toast]);

  useBroadcastReceiver('_ah_auth_success', () => {
    invalidateUser();

    if (launch) {
      setLaunch(launch, getLaunchParams(launchParams));
    }
  });

  useBroadcastReceiver('_ah_auth_failed', () => {
    toast.warning('Unable to connect to Google, permission not granted');
  });

  const handleExtensionGoogleAuth = () => {
    const href = getAuthUrl(currentUser, { isExtension, email }, undefined, {});
    openPopup({ href });
  };

  return {
    onAuth: handleExtensionGoogleAuth,
  };
};

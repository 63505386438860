import { useMatch } from 'react-router-dom';

type Ret = {
  isMagic: boolean;
  isMagicPage: boolean;
};

export const useIsMagic = (): Ret => {
  const isMagic = useMatch('/magic/*') !== null;
  const isMagicPage = useMatch('/magic-page') !== null;

  return { isMagic, isMagicPage };
};
